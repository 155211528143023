export default [
    'election',
    'election',
    'election',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'health',
    'density',
    '2000',
    '2010',
    '2000',
    '2010',
    'density',
    '2000',
    '2010',
    '2000',
    '2010',
    'density',
    '2000',
    '2010',
    '2000',
    '2010',
    'density',
    '2000',
    '2010',
    '2000',
    '2010',
    'density',
    '2000',
    '2010',
    '2000',
    '2010',
    'geography',
    'race',
    '2000',
    '2010',
    'birthplace',
    'birthplace',
    'birthplace',
    'race',
    '2000',
    '2010',
    'citizenship',
    'citizenship',
    'citizenship',
    'geography',
    'weather',
    'weather',
    'weather',
    'weather',
    'weather',
    'weather',
    'canadian-census',
    'canadian-census',
    'canadian-census',
    'canadian-census',
    'canadian-census',
    'education',
    'education',
    'education',
    'education',
    'canadian-census-disaggregated',
    'education',
    'canadian-census-disaggregated',
    'education',
    'canadian-census-disaggregated',
    'education',
    'education',
    'education',
    'generation',
    'canadian-census-disaggregated',
    'generation',
    'canadian-census-disaggregated',
    'generation',
    'canadian-census-disaggregated',
    'generation',
    'canadian-census-disaggregated',
    'generation',
    'canadian-census-disaggregated',
    'generation',
    'canadian-census-disaggregated',
    'gpw',
    'gpw',
    'gpw',
    'gpw',
    'gpw',
    'elevation_hilliness',
    'elevation_hilliness',
    'race',
    '2000',
    '2010',
    'climate-acs',
    'climate-acs',
    'climate-acs',
    'climate-acs',
    'climate-acs',
    'climate-acs',
    'race',
    '2000',
    '2010',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'weather',
    'canadian-census-disaggregated',
    'income',
    'canadian-census-disaggregated',
    'income',
    'canadian-census-disaggregated',
    'income',
    'housing-census',
    '2000',
    '2010',
    'canadian-census-disaggregated',
    'income',
    'canadian-census-disaggregated',
    'income',
    'canadian-census-disaggregated',
    'income',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'industry_and_occupation',
    'canadian-census-disaggregated',
    'insurance',
    'insurance',
    'insurance',
    'internet',
    'language',
    'language',
    'language',
    'usda_fra',
    'usda_fra',
    'usda_fra',
    'usda_fra',
    'canadian-census-disaggregated',
    'marriage',
    'canadian-census-disaggregated',
    'marriage',
    'canadian-census-disaggregated',
    'marriage',
    'canadian-census-disaggregated',
    'superfund',
    'airport',
    'hospital',
    'school',
    'weather',
    'weather',
    'weather',
    'weather',
    'weather',
    'weather',
    'weather',
    'race',
    '2000',
    '2010',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'industry_and_occupation',
    'race',
    '2000',
    '2010',
    'park',
    'population',
    '2000',
    '2010',
    'canadian-census',
    '2000',
    '2010',
    'income',
    'weather',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'density',
    '2000',
    '2010',
    'canadian-census',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'segregation',
    'weather',
    'sors',
    'sors',
    'sors',
    'sors',
    'sors',
    'nhtsa_accidents',
    'nhtsa_accidents',
    'nhtsa_accidents',
    'nhtsa_accidents',
    'transportation',
    'canadian-census-disaggregated',
    'transportation',
    'canadian-census-disaggregated',
    'transportation',
    'canadian-census-disaggregated',
    'transportation',
    'canadian-census-disaggregated',
    'transportation',
    'transportation',
    'transportation',
    'transportation',
    'transportation',
    'housing-census',
    '2000',
    '2010',
    'transportation',
    'transportation',
    'transportation',
    'race',
    '2000',
    '2010',
    'weather',
    'superfund',
    'airport',
    'hospital',
    'school',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
    'housing-acs',
] as const
