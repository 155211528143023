const value: Record<string, number[]> = {
    'world__overall': [
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        2,
        1,
    ],
    'world__5M Person Circle': [
        316,
        3,
    ],
    'world__CA Census Subdivision': [
        106,
        106,
        106,
        1,
    ],
    'world__CCD': [
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
    ],
    'world__City': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        7,
    ],
    'world__County': [
        159,
        159,
        1,
    ],
    'world__County Cross CD': [
        137,
        137,
        45,
    ],
    'world__Historical Congressional District': [
        54,
        54,
        54,
        54,
        54,
        49,
    ],
    'world__Hospital Service Area': [
        149,
        149,
        21,
    ],
    'world__Neighborhood': [
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        9,
    ],
    'world__School District': [
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        15,
    ],
    'world__State House District': [
        105,
        105,
        105,
        4,
    ],
    'world__State Senate District': [
        267,
        52,
    ],
    'world__Subnational Region': [
        140,
        140,
        39,
    ],
    'world__Urban Area': [
        195,
        124,
    ],
    'world__Urban Center': [
        50,
        50,
        50,
        50,
        50,
        50,
        19,
    ],
    'world__ZIP': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        7,
    ],
    'Africa__overall': [
        143,
        143,
        33,
    ],
    'Africa__Urban Center': [
        257,
        62,
    ],
    'Asia__overall': [
        59,
        59,
        59,
        59,
        59,
        24,
    ],
    'Asia__Urban Center': [
        89,
        89,
        89,
        52,
    ],
    'Europe__overall': [
        226,
        93,
    ],
    'North America__overall': [
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        1,
    ],
    'North America__CA Census Subdivision': [
        106,
        106,
        106,
        1,
    ],
    'North America__CCD': [
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
    ],
    'North America__City': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        7,
    ],
    'North America__County': [
        159,
        159,
        1,
    ],
    'North America__County Cross CD': [
        137,
        137,
        45,
    ],
    'North America__Historical Congressional District': [
        54,
        54,
        54,
        54,
        54,
        49,
    ],
    'North America__Hospital Service Area': [
        149,
        149,
        21,
    ],
    'North America__Neighborhood': [
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        9,
    ],
    'North America__School District': [
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        15,
    ],
    'North America__State House District': [
        106,
        106,
        106,
        1,
    ],
    'North America__State Senate District': [
        270,
        49,
    ],
    'North America__Urban Area': [
        196,
        123,
    ],
    'North America__ZIP': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        7,
    ],
    'Canada__overall': [
        75,
        75,
        75,
        75,
        19,
    ],
    'Canada__CA Census Subdivision': [
        106,
        106,
        106,
        1,
    ],
    'China__overall': [
        207,
        112,
    ],
    'China__Urban Center': [
        284,
        35,
    ],
    'India__overall': [
        205,
        114,
    ],
    'India__Urban Center': [
        282,
        37,
    ],
    'USA__overall': [
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        3,
        1,
    ],
    'USA__CCD': [
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
        11,
    ],
    'USA__City': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        7,
    ],
    'USA__County': [
        159,
        159,
        1,
    ],
    'USA__County Cross CD': [
        137,
        137,
        45,
    ],
    'USA__Historical Congressional District': [
        54,
        54,
        54,
        54,
        54,
        49,
    ],
    'USA__Hospital Service Area': [
        149,
        149,
        21,
    ],
    'USA__Neighborhood': [
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        31,
        9,
    ],
    'USA__School District': [
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        38,
        15,
    ],
    'USA__State House District': [
        105,
        105,
        105,
        4,
    ],
    'USA__State Senate District': [
        267,
        52,
    ],
    'USA__Urban Area': [
        195,
        124,
    ],
    'USA__ZIP': [
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        13,
        7,
    ],
    'Alabama, USA__overall': [
        190,
        129,
    ],
    'Arizona, USA__overall': [
        215,
        104,
    ],
    'Arkansas, USA__overall': [
        157,
        157,
        5,
    ],
    'California, USA__overall': [
        61,
        61,
        61,
        61,
        61,
        14,
    ],
    'California, USA__Neighborhood': [
        258,
        61,
    ],
    'California, USA__ZIP': [
        295,
        24,
    ],
    'Colorado, USA__overall': [
        215,
        104,
    ],
    'Florida, USA__overall': [
        114,
        114,
        91,
    ],
    'Georgia, USA__overall': [
        134,
        134,
        51,
    ],
    'Illinois, USA__overall': [
        73,
        73,
        73,
        73,
        27,
    ],
    'Illinois, USA__CCD': [
        305,
        14,
    ],
    'Indiana, USA__overall': [
        111,
        111,
        97,
    ],
    'Iowa, USA__overall': [
        106,
        106,
        106,
        1,
    ],
    'Iowa, USA__CCD': [
        313,
        6,
    ],
    'Kansas, USA__overall': [
        124,
        124,
        71,
    ],
    'Kentucky, USA__overall': [
        175,
        144,
    ],
    'Louisiana, USA__overall': [
        214,
        105,
    ],
    'Maine, USA__overall': [
        287,
        32,
    ],
    'Maryland, USA__overall': [
        249,
        70,
    ],
    'Massachusetts, USA__overall': [
        207,
        112,
    ],
    'Michigan, USA__overall': [
        100,
        100,
        100,
        19,
    ],
    'Minnesota, USA__overall': [
        85,
        85,
        85,
        64,
    ],
    'Minnesota, USA__CCD': [
        187,
        132,
    ],
    'Mississippi, USA__overall': [
        236,
        83,
    ],
    'Missouri, USA__overall': [
        97,
        97,
        97,
        28,
    ],
    'Montana, USA__overall': [
        274,
        45,
    ],
    'Nebraska, USA__overall': [
        166,
        153,
    ],
    'New Jersey, USA__overall': [
        165,
        154,
    ],
    'New Mexico, USA__overall': [
        298,
        21,
    ],
    'New York, USA__overall': [
        70,
        70,
        70,
        70,
        39,
    ],
    'New York, USA__ZIP': [
        293,
        26,
    ],
    'North Carolina, USA__overall': [
        117,
        117,
        85,
    ],
    'North Dakota, USA__overall': [
        166,
        153,
    ],
    'North Dakota, USA__CCD': [
        296,
        23,
    ],
    'Ohio, USA__overall': [
        78,
        78,
        78,
        78,
        7,
    ],
    'Oklahoma, USA__overall': [
        153,
        153,
        13,
    ],
    'Oregon, USA__overall': [
        272,
        47,
    ],
    'Pennsylvania, USA__overall': [
        58,
        58,
        58,
        58,
        58,
        29,
    ],
    'Pennsylvania, USA__CCD': [
        200,
        119,
    ],
    'Pennsylvania, USA__City': [
        274,
        45,
    ],
    'Pennsylvania, USA__ZIP': [
        290,
        29,
    ],
    'South Carolina, USA__overall': [
        251,
        68,
    ],
    'South Dakota, USA__overall': [
        188,
        131,
    ],
    'Tennessee, USA__overall': [
        143,
        143,
        33,
    ],
    'Texas, USA__overall': [
        56,
        56,
        56,
        56,
        56,
        39,
    ],
    'Texas, USA__City': [
        279,
        40,
    ],
    'Texas, USA__ZIP': [
        268,
        51,
    ],
    'Virginia, USA__overall': [
        131,
        131,
        57,
    ],
    'Washington, USA__overall': [
        180,
        139,
    ],
    'West Virginia, USA__overall': [
        266,
        53,
    ],
    'Wisconsin, USA__overall': [
        100,
        100,
        100,
        19,
    ],
    'Wisconsin, USA__CCD': [
        273,
        46,
    ],
    'Quebec, Canada__overall': [
        308,
        11,
    ],
}
export default value
