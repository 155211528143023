export default [
    {
        allGeographies: [
            'AK-00, USA',
            'AL-01, USA',
            'AL-02, USA',
            'AL-03, USA',
            'AL-04, USA',
            'AL-05, USA',
            'AL-06, USA',
            'AL-07, USA',
            'AR-01, USA',
            'AR-02, USA',
            'AR-03, USA',
            'AR-04, USA',
            'AZ-01, USA',
            'AZ-02, USA',
            'AZ-03, USA',
            'AZ-04, USA',
            'AZ-05, USA',
            'AZ-06, USA',
            'AZ-07, USA',
            'AZ-08, USA',
            'AZ-09, USA',
            'Abia, Nigeria',
            'Abidjan Urban Center, Ivory Coast',
            'Abidjan, Ivory Coast',
            'Abilene-Sweetwater TX Media Market, USA',
            'Abuja Federal Capital Territory, Nigeria',
            'Ab\u016b Z\u0327aby, United Arab Emirates',
            'Accra Urban Center, Ghana',
            'Aceh, Indonesia',
            'Ad Daqahl\u012byah, Egypt',
            'Ada County, Idaho, USA',
            'Adamawa, Nigeria',
            'Adams County, Colorado, USA',
            'Addis Ababa Urban Center, Ethiopia',
            'Afghanistan',
            'Agra Urban Center, India',
            'Aguadilla-Isabela MSA, PR, USA',
            'Ahmedabad Urban Center, India',
            'Aiti, Japan',
            'Akron MSA, OH, USA',
            'Akron [Urban Area], OH, USA',
            'Akwa Ibom, Nigeria',
            'Al Ba\u015frah, Iraq',
            'Al Ba\u1e29r al A\u1e29mar, Sudan',
            'Al Buh\u0327ayrah, Egypt',
            'Al Fayy\u016bm, Egypt',
            'Al Gharb\u012byah, Egypt',
            'Al Iskandariyah, Egypt',
            'Al Jaz\u012brah, Sudan',
            'Al J\u012bzah, Egypt',
            'Al Khar\u0163\u016bm, Sudan',
            'Al Miny\u0101, Egypt',
            'Al Min\u016bf\u012byah, Egypt',
            'Al Qaly\u016bb\u012byah, Egypt',
            'Al Q\u0101hirah, Egypt',
            'Al \u1e28udaydah, Yemen',
            'Al \u2018A\u0305\u015fimah, Jordan',
            'Alabama, USA',
            'Alachua County, Florida, USA',
            'Alagoas, Brazil',
            'Alameda County, California, USA',
            'Alaska, USA',
            'Albania',
            'Albany County, New York, USA',
            'Albany GA Media Market, USA',
            'Albany-Schenectady [Urban Area], NY, USA',
            'Albany-Schenectady-Troy MSA, NY, USA',
            'Albany-Schenectady-Troy NY Media Market, USA',
            'Alberta, Canada',
            'Albuquerque MSA, NM, USA',
            'Albuquerque Urban Center, USA',
            'Albuquerque [Urban Area], NM, USA',
            'Albuquerque city, New Mexico, USA',
            'Albuquerque-Santa Fe NM Media Market, USA',
            'Alexandria Urban Center, Egypt',
            'Algeria',
            'Algiers Urban Center, Algeria',
            'Allegheny County, Pennsylvania, USA',
            'Allen County, Indiana, USA',
            'Allentown Urban Center, USA',
            'Allentown-Bethlehem [Urban Area], PA-NJ, USA',
            'Allentown-Bethlehem-Easton MSA, PA-NJ, USA',
            'Almaty oblysy, Kazakhstan',
            'Amarillo MSA, TX, USA',
            'Amarillo TX Media Market, USA',
            'Amazonas, Brazil',
            'Amman Urban Center, Jordan',
            'Am\u0101nat al \u2018\u0100\u015fimah [city], Yemen',
            'An N\u012bl al Abya\u1e11, Sudan',
            'Anaheim city, California, USA',
            'Analamanga, Madagascar',
            'Anambra, Nigeria',
            'Anchorage AK Media Market, USA',
            'Anchorage MSA, AK, USA',
            'Anchorage Municipality, Alaska, USA',
            'Anchorage municipality, Alaska, USA',
            'Andaluc\u00eda, Spain',
            'Andhra Pradesh, India',
            'Andijon, Uzbekistan',
            'Angola',
            'Anhui Sheng, China',
            'Ankara Urban Center, Turkey',
            'Ankara, Turkey',
            'Ann Arbor MSA, MI, USA',
            'Ann Arbor [Urban Area], MI, USA',
            'Anne Arundel County, Maryland, USA',
            'Anoka County, Minnesota, USA',
            'Antalya, Turkey',
            'Antananarivo Urban Center, Madagascar',
            'Antioch Urban Center, USA',
            'Antioch [Urban Area], CA, USA',
            'Antioquia, Colombia',
            'Ar Riy\u0101\u1e11, Saudi Arabia',
            'Arapahoe County, Colorado, USA',
            'Arb\u012bl, Iraq',
            'Argentina',
            'Arizona, USA',
            'Arkansas, USA',
            'Arlington city, Texas, USA',
            'Armenia',
            'Asansol Urban Center, India',
            'Ash Sharq\u012byah, Egypt',
            'Ash Sharq\u012byah, Saudi Arabia',
            'Ashanti, Ghana',
            'Asheville MSA, NC, USA',
            'Asheville [Urban Area], NC, USA',
            'Assam, India',
            'Asy\u016b\u0163, Egypt',
            'Athens Urban Center, Greece',
            'Atlanta GA Media Market, USA',
            'Atlanta Urban Center, USA',
            'Atlanta [Urban Area], GA, USA',
            'Atlanta city, Georgia, USA',
            'Atlanta-Sandy Springs-Roswell MSA, GA, USA',
            'Atlantic City-Hammonton MSA, NJ, USA',
            'Atlantic City-Ocean City-Villas [Urban Area], NJ, USA',
            'Atlantic County, New Jersey, USA',
            'Atl\u00e1ntico, Colombia',
            'Attik\u00ed, Greece',
            'Augusta GA Media Market, USA',
            'Augusta-Richmond County MSA, GA-SC, USA',
            'Augusta-Richmond County [Urban Area], GA-SC, USA',
            'Aurora city, Colorado, USA',
            'Austin TX Media Market, USA',
            'Austin Urban Center, USA',
            'Austin [Urban Area], TX, USA',
            'Austin city, Texas, USA',
            'Austin-Round Rock MSA, TX, USA',
            'Australia',
            'Austria',
            'Auvergne-Rh\u00f4ne-Alpes, France',
            'Ayeyarwady, Myanmar',
            'Azad Jammu and Kashmir, Pakistan',
            'Azerbaijan',
            'Azerbaijan, Azerbaijan',
            'Baden-W\u00fcrttemberg, Germany',
            'Baghdad Urban Center, Iraq',
            'Baghd\u0101d, Iraq',
            'Bago, Myanmar',
            'Bahia, Brazil',
            'Baja California, Mexico',
            'Bakersfield CA Media Market, USA',
            'Bakersfield MSA, CA, USA',
            'Bakersfield Urban Center, USA',
            'Bakersfield [Urban Area], CA, USA',
            'Bakersfield city, California, USA',
            'Bali, Indonesia',
            'Balochistan, Pakistan',
            'Baltimore County, Maryland, USA',
            'Baltimore MD Media Market, USA',
            'Baltimore Urban Center, USA',
            'Baltimore [Urban Area], MD, USA',
            'Baltimore city, Maryland, USA',
            'Baltimore city-County, Maryland, USA',
            'Baltimore-Columbia-Towson MSA, MD, USA',
            'Bamako Urban Center, Mali',
            'Bamako, Mali',
            'Banaadir, Somalia',
            'Bandung Urban Center, Indonesia',
            'Bangkok Urban Center, Thailand',
            'Bangladesh',
            'Bangor ME Media Market, USA',
            'Banten, Indonesia',
            'Ban\u012b Suwayf, Egypt',
            'Barcelona Urban Center, Spain',
            'Barishal, Bangladesh',
            'Barnstable Town [Urban Area], MA, USA',
            'Bas-Sassandra, Ivory Coast',
            'Bashkortostan, Respublika, Russia',
            'Basn\u0101hira pa\u1e37\u0101ta, Sri Lanka',
            'Batangas, Philippines',
            'Baton Rouge LA Media Market, USA',
            'Baton Rouge MSA, LA, USA',
            'Baton Rouge Urban Center, USA',
            'Baton Rouge [Urban Area], LA, USA',
            'Bauchi, Nigeria',
            'Bayelsa, Nigeria',
            'Bayern, Germany',
            'Beaumont-Port Arthur MSA, TX, USA',
            'Beaumont-Port Arthur TX Media Market, USA',
            'Beijing Shi, China',
            'Beijing Urban Center, China',
            'Belarus',
            'Belgium',
            'Bell County, Texas, USA',
            'Belo Horizonte Urban Center, Brazil',
            'Bengaluru Urban Center, India',
            'Benin',
            'Benton County, Arkansas, USA',
            'Benue, Nigeria',
            'Bergen County, New Jersey, USA',
            'Berks County, Pennsylvania, USA',
            'Berlin Urban Center, Germany',
            'Berlin, Germany',
            'Bernalillo County, New Mexico, USA',
            'Bexar County, Texas, USA',
            'Bih\u0101r, India',
            'Billings MT Media Market, USA',
            'Biloxi-Gulfport MS Media Market, USA',
            'Binghamton NY Media Market, USA',
            'Birmingham (Anniston & Tuscaloosa) AL Media Market, USA',
            'Birmingham Urban Center, United Kingdom',
            'Birmingham [Urban Area], AL, USA',
            'Birmingham-Hoover MSA, AL, USA',
            'Bluefield-Beckley-Oak Hill WV Media Market, USA',
            'Bogota Urban Center, Colombia',
            'Boise City MSA, ID, USA',
            'Boise City [Urban Area], ID, USA',
            'Boise ID Media Market, USA',
            'Boise Urban Center, USA',
            'Bolivia',
            'Bonita Springs-Estero [Urban Area], FL, USA',
            'Borno, Nigeria',
            'Bosnia and Herzegovina',
            'Boston MA (Manchester NH) Media Market, USA',
            'Boston Urban Center, USA',
            'Boston [Urban Area], MA-NH, USA',
            'Boston city, Massachusetts, USA',
            'Boston-Cambridge-Newton MSA, MA-NH, USA',
            'Botswana',
            'Boulder County, Colorado, USA',
            'Boulder MSA, CO, USA',
            'Bourgogne-Franche-Comt\u00e9, France',
            'Bradenton Urban Center, USA',
            'Bradenton-Sarasota-Venice [Urban Area], FL, USA',
            'Brampton City, Peel Regional municipality, Ontario, Canada',
            'Brandenburg, Germany',
            'Brazil',
            'Brazoria County, Texas, USA',
            'Brazzaville Urban Center, Congo',
            'Bremerton-Silverdale MSA, WA, USA',
            'Bretagne, France',
            'Brevard County, Florida, USA',
            'Brick Urban Center, USA',
            'Bridgeport Urban Center, USA',
            'Bridgeport-Stamford [Urban Area], CT-NY, USA',
            'Bridgeport-Stamford-Norwalk MSA, CT, USA',
            'Bristol County, Massachusetts, USA',
            'British Columbia, Canada',
            'Bronx County, New York, USA',
            'Broward County, Florida, USA',
            'Brown County, Wisconsin, USA',
            'Brownsville-Harlingen MSA, TX, USA',
            'Bucks County, Pennsylvania, USA',
            'Buenos Aires Urban Center, Argentina',
            'Buenos Aires, Argentina',
            'Buffalo NY Media Market, USA',
            'Buffalo Urban Center, USA',
            'Buffalo [Urban Area], NY, USA',
            'Buffalo city, New York, USA',
            'Buffalo-Cheektowaga-Niagara Falls MSA, NY, USA',
            'Bulacan, Philippines',
            'Bulgaria',
            'Buncombe County, North Carolina, USA',
            'Burkina Faso',
            'Burlington County, New Jersey, USA',
            'Burlington VT-Plattsburgh NY Media Market, USA',
            'Bursa, Turkey',
            'Burundi',
            'Busan Urban Center, South Korea',
            'Busan-gwangyeoksi, South Korea',
            'Butler County, Ohio, USA',
            'B\u00e9ni Mellal-Kh\u00e9nifra, Morocco',
            'B\u0101bil, Iraq',
            'B\u0101gmat\u012b, Nepal',
            'CA-01, USA',
            'CA-02, USA',
            'CA-03, USA',
            'CA-04, USA',
            'CA-05, USA',
            'CA-06, USA',
            'CA-07, USA',
            'CA-08, USA',
            'CA-09, USA',
            'CA-10, USA',
            'CA-11, USA',
            'CA-12, USA',
            'CA-13, USA',
            'CA-14, USA',
            'CA-15, USA',
            'CA-16, USA',
            'CA-17, USA',
            'CA-18, USA',
            'CA-19, USA',
            'CA-20, USA',
            'CA-21, USA',
            'CA-22, USA',
            'CA-23, USA',
            'CA-24, USA',
            'CA-25, USA',
            'CA-26, USA',
            'CA-27, USA',
            'CA-28, USA',
            'CA-29, USA',
            'CA-30, USA',
            'CA-31, USA',
            'CA-32, USA',
            'CA-33, USA',
            'CA-34, USA',
            'CA-35, USA',
            'CA-36, USA',
            'CA-37, USA',
            'CA-38, USA',
            'CA-39, USA',
            'CA-40, USA',
            'CA-41, USA',
            'CA-42, USA',
            'CA-43, USA',
            'CA-44, USA',
            'CA-45, USA',
            'CA-46, USA',
            'CA-47, USA',
            'CA-48, USA',
            'CA-49, USA',
            'CA-50, USA',
            'CA-51, USA',
            'CA-52, USA',
            'CO-01, USA',
            'CO-02, USA',
            'CO-03, USA',
            'CO-04, USA',
            'CO-05, USA',
            'CO-06, USA',
            'CO-07, USA',
            'CO-08, USA',
            'CT-01, USA',
            'CT-02, USA',
            'CT-03, USA',
            'CT-04, USA',
            'CT-05, USA',
            'Cairo Urban Center, Egypt',
            'Calgary CMA, AB, Canada',
            'Calgary City, Division No. 6, Alberta, Canada',
            'Calgary Population Center, AB, Canada',
            'Calgary Urban Center, Canada',
            'Cali Urban Center, Colombia',
            'California, USA',
            'Cambodia',
            'Camden County, New Jersey, USA',
            'Cameron County, Texas, USA',
            'Cameroon',
            'Campania, Italy',
            'Canada',
            'Canton [Urban Area], OH, USA',
            'Canton-Massillon MSA, OH, USA',
            'Cape Coral Urban Center, USA',
            'Cape Coral [Urban Area], FL, USA',
            'Cape Coral-Fort Myers MSA, FL, USA',
            'Cape Town Urban Center, South Africa',
            'Capital Regional district, British Columbia, Canada',
            'Capitol Planning Region, Connecticut, USA',
            'Caracas Urban Center, Venezuela',
            'Casablanca Urban Center, Morocco',
            'Casablanca-Settat, Morocco',
            'Catalunya, Spain',
            'Cavite, Philippines',
            'Cear\u00e1, Brazil',
            'Cebu City Urban Center, Philippines',
            'Cebu, Philippines',
            'Cedar Rapids MSA, IA, USA',
            'Cedar Rapids-Waterloo-Iowa City & Dubuque IA Media Market, USA',
            'Central African Republic',
            'Central, Ghana',
            'Centre, Burkina Faso',
            'Centre, Cameroon',
            'Centre-Val de Loire, France',
            'Chad',
            'Champaign & Springfield-Decatur IL Media Market, USA',
            'Chandler city, Arizona, USA',
            'Changchun Urban Center, China',
            'Changsha Urban Center, China',
            'Changzhou Urban Center, China',
            'Charleston County, South Carolina, USA',
            'Charleston SC Media Market, USA',
            'Charleston [Urban Area], SC, USA',
            'Charleston-Huntington WV Media Market, USA',
            'Charleston-North Charleston MSA, SC, USA',
            'Charlotte NC Media Market, USA',
            'Charlotte Urban Center, USA',
            'Charlotte [Urban Area], NC-SC, USA',
            'Charlotte city, North Carolina, USA',
            'Charlotte-Concord-Gastonia MSA, NC-SC, USA',
            'Chatham County, Georgia, USA',
            'Chattanooga MSA, TN-GA, USA',
            'Chattanooga TN Media Market, USA',
            'Chattanooga [Urban Area], TN-GA, USA',
            'Chattogram Urban Center, Bangladesh',
            'Chattogram, Bangladesh',
            'Chelyabinskaya oblast\', Russia',
            'Chengdu Urban Center, China',
            'Chennai Urban Center, India',
            'Cherokee County, Georgia, USA',
            'Chester County, Pennsylvania, USA',
            'Chesterfield County, Virginia, USA',
            'Chhatt\u012bsgarh, India',
            'Chiapas, Mexico',
            'Chicago IL Media Market, USA',
            'Chicago Urban Center, USA',
            'Chicago [Urban Area], IL-IN, USA',
            'Chicago city, Illinois, USA',
            'Chicago-Naperville-Elgin MSA, IL-IN-WI, USA',
            'Chico-Redding CA Media Market, USA',
            'Chihuahua, Mexico',
            'Chile',
            'China',
            'Chongqing Shi, China',
            'Chongqing Urban Center, China',
            'Chula Vista city, California, USA',
            'Cincinnati MSA, OH-KY-IN, USA',
            'Cincinnati OH Media Market, USA',
            'Cincinnati Urban Center, USA',
            'Cincinnati [Urban Area], OH-KY, USA',
            'Cincinnati city, Ohio, USA',
            'Ciudad Ju\u00e1rez Urban Center, Mexico-USA',
            'Ciudad de M\u00e9xico, Mexico',
            'Clackamas County, Oregon, USA',
            'Clark County, Nevada, USA',
            'Clark County, Washington, USA',
            'Clarksburg-Weston WV Media Market, USA',
            'Clarksville MSA, TN-KY, USA',
            'Clay County, Missouri, USA',
            'Clayton County, Georgia, USA',
            'Cleveland County, Oklahoma, USA',
            'Cleveland Urban Center, USA',
            'Cleveland [Urban Area], OH, USA',
            'Cleveland city, Ohio, USA',
            'Cleveland-Akron (Canton) OH Media Market, USA',
            'Cleveland-Elyria MSA, OH, USA',
            'Coahuila de Zaragoza, Mexico',
            'Cobb County, Georgia, USA',
            'College Station-Bryan MSA, TX, USA',
            'Collier County, Florida, USA',
            'Collin County, Texas, USA',
            'Colombia',
            'Colombo Urban Center, Sri Lanka',
            'Colorado Springs MSA, CO, USA',
            'Colorado Springs Urban Center, USA',
            'Colorado Springs [Urban Area], CO, USA',
            'Colorado Springs city, Colorado, USA',
            'Colorado Springs-Pueblo CO Media Market, USA',
            'Colorado, USA',
            'Columbia MSA, SC, USA',
            'Columbia SC Media Market, USA',
            'Columbia [Urban Area], SC, USA',
            'Columbia-Jefferson City MO Media Market, USA',
            'Columbus (Ohio) Urban Center, USA',
            'Columbus GA Media Market, USA',
            'Columbus MSA, GA-AL, USA',
            'Columbus MSA, OH, USA',
            'Columbus OH Media Market, USA',
            'Columbus [Urban Area], GA-AL, USA',
            'Columbus [Urban Area], OH, USA',
            'Columbus city, Ohio, USA',
            'Columbus-Tupelo-West Point MS Media Market, USA',
            'Comilla Urban Center, Bangladesh',
            'Conakry Urban Center, Guinea',
            'Concord Urban Center, USA',
            'Concord [Urban Area], NC, USA',
            'Concord-Walnut Creek [Urban Area], CA, USA',
            'Congo',
            'Connecticut, USA',
            'Contra Costa County, California, USA',
            'Cook County, Illinois, USA',
            'Copperbelt, Zambia',
            'Corpus Christi MSA, TX, USA',
            'Corpus Christi TX Media Market, USA',
            'Corpus Christi [Urban Area], TX, USA',
            'Corpus Christi city, Texas, USA',
            'Costa Rica',
            'Crestview-Fort Walton Beach-Destin MSA, FL, USA',
            'Croatia',
            'Cross River, Nigeria',
            'Cuba',
            'Cumberland County, Maine, USA',
            'Cumberland County, North Carolina, USA',
            'Cumberland County, Pennsylvania, USA',
            'Cundinamarca, Colombia',
            'Cuyahoga County, Ohio, USA',
            'Czech Republic',
            'C\u00f3rdoba, Argentina',
            'DE-00, USA',
            'Dagestan, Respublika, Russia',
            'Dakar Urban Center, Senegal',
            'Dakar, Senegal',
            'Dakota County, Minnesota, USA',
            'Daku\u1e47u pa\u1e37\u0101ta, Sri Lanka',
            'Dalian Urban Center, China',
            'Dallas County, Texas, USA',
            'Dallas Urban Center, USA',
            'Dallas city, Texas, USA',
            'Dallas-Fort Worth-Arlington MSA, TX, USA',
            'Dallas-Fort Worth-Arlington [Urban Area], TX, USA',
            'Dallas-Ft.Worth TX Media Market, USA',
            'Damascus Urban Center, Syria',
            'Dane County, Wisconsin, USA',
            'Dar es Salaam Urban Center, Tanzania',
            'Dar es Salaam, Tanzania',
            'Dauphin County, Pennsylvania, USA',
            'Davao del Sur, Philippines',
            'Davenport IA-Rock Island-Moline IL Media Market, USA',
            'Davenport [Urban Area], IA-IL, USA',
            'Davenport-Moline-Rock Island MSA, IA-IL, USA',
            'Davidson County, Tennessee, USA',
            'Davis County, Utah, USA',
            'Dayton MSA, OH, USA',
            'Dayton OH Media Market, USA',
            'Dayton Urban Center, USA',
            'Dayton [Urban Area], OH, USA',
            'Daytona Beach-Palm Coast-Port Orange [Urban Area], FL, USA',
            'DeKalb County, Georgia, USA',
            'Delaware County, Pennsylvania, USA',
            'Delaware, USA',
            'Delhi [New Delhi] Urban Center, India',
            'Delhi, India',
            'Delta, Nigeria',
            'Deltona-Daytona Beach-Ormond Beach MSA, FL, USA',
            'Democratic Republic of the Congo',
            'Denmark',
            'Denton County, Texas, USA',
            'Denton-Lewisville [Urban Area], TX, USA',
            'Denver CO Media Market, USA',
            'Denver County, Colorado, USA',
            'Denver Urban Center, USA',
            'Denver city, Colorado, USA',
            'Denver-Aurora [Urban Area], CO, USA',
            'Denver-Aurora-Lakewood MSA, CO, USA',
            'Des Moines Urban Center, USA',
            'Des Moines [Urban Area], IA, USA',
            'Des Moines-Ames IA Media Market, USA',
            'Des Moines-West Des Moines MSA, IA, USA',
            'Detroit MI Media Market, USA',
            'Detroit Urban Center, Canada-USA',
            'Detroit [Urban Area], MI, USA',
            'Detroit city, Michigan, USA',
            'Detroit-Warren-Dearborn MSA, MI, USA',
            'Dhaka Urban Center, Bangladesh',
            'Dhaka, Bangladesh',
            'District of Columbia, District of Columbia, USA',
            'District of Columbia, USA',
            'Distrito Capital de Bogot\u00e1, Colombia',
            'Distrito Federal, Brazil',
            'Division No. 1, Newfoundland and Labrador, Canada',
            'Division No. 11, Alberta, Canada',
            'Division No. 11, Manitoba, Canada',
            'Division No. 11, Saskatchewan, Canada',
            'Division No. 6, Alberta, Canada',
            'Division No. 6, Saskatchewan, Canada',
            'Dnipropetrovska oblast, Ukraine',
            'Dodoma, Tanzania',
            'Dolno\u015bl\u0105skie, Poland',
            'Dominican Republic',
            'Donets\'ka oblast, Ukraine',
            'Dortmund Urban Center, Germany',
            'Dosso, Niger',
            'Dothan AL Media Market, USA',
            'Douala Urban Center, Cameroon',
            'Douglas County, Colorado, USA',
            'Douglas County, Nebraska, USA',
            'DuPage County, Illinois, USA',
            'Dubai Urban Center, United Arab Emirates',
            'Dubayy, United Arab Emirates',
            'Duluth MN-Superior WI Media Market, USA',
            'Duluth MSA, MN-WI, USA',
            'Durban Urban Center, South Africa',
            'Durham County, North Carolina, USA',
            'Durham Regional municipality, Ontario, Canada',
            'Durham [Urban Area], NC, USA',
            'Durham city, North Carolina, USA',
            'Durham-Chapel Hill MSA, NC, USA',
            'Dutchess County, New York, USA',
            'Duval County, Florida, USA',
            'East Baton Rouge Parish, Louisiana, USA',
            'Eastern Cape, South Africa',
            'Eastern Equatoria, South Sudan',
            'Eastern, Ghana',
            'Ebonyi, Nigeria',
            'Ecuador',
            'Edmonton CMA, AB, Canada',
            'Edmonton City, Division No. 11, Alberta, Canada',
            'Edmonton Population Center, AB, Canada',
            'Edmonton Urban Center, Canada',
            'Edo, Nigeria',
            'Egypt',
            'Ekiti, Nigeria',
            'El Paso County, Colorado, USA',
            'El Paso County, Texas, USA',
            'El Paso MSA, TX, USA',
            'El Paso TX (Las Cruces NM) Media Market, USA',
            'El Paso [Urban Area], TX-NM, USA',
            'El Paso city, Texas, USA',
            'El Salvador',
            'Emilia-Romagna, Italy',
            'England, United Kingdom',
            'Enugu, Nigeria',
            'Erie County, New York, USA',
            'Erie County, Pennsylvania, USA',
            'Erie MSA, PA, USA',
            'Erie PA Media Market, USA',
            'Eritrea',
            'Escambia County, Florida, USA',
            'Esp\u00edrito Santo, Brazil',
            'Essex County, Massachusetts, USA',
            'Essex County, New Jersey, USA',
            'Essex County, Ontario, Canada',
            'Est, Rwanda',
            'Ethiopia',
            'Eugene MSA, OR, USA',
            'Eugene OR Media Market, USA',
            'Eugene [Urban Area], OR, USA',
            'Evansville IN Media Market, USA',
            'Evansville MSA, IN-KY, USA',
            'E\u015ffah\u0101n, Iran',
            'FL-01, USA',
            'FL-02, USA',
            'FL-03, USA',
            'FL-04, USA',
            'FL-05, USA',
            'FL-06, USA',
            'FL-07, USA',
            'FL-08, USA',
            'FL-09, USA',
            'FL-10, USA',
            'FL-11, USA',
            'FL-12, USA',
            'FL-13, USA',
            'FL-14, USA',
            'FL-15, USA',
            'FL-16, USA',
            'FL-17, USA',
            'FL-18, USA',
            'FL-19, USA',
            'FL-20, USA',
            'FL-21, USA',
            'FL-22, USA',
            'FL-23, USA',
            'FL-24, USA',
            'FL-25, USA',
            'FL-26, USA',
            'FL-27, USA',
            'FL-28, USA',
            'Fairfax County, Virginia, USA',
            'Faisalabad Urban Center, Pakistan',
            'Far North, Cameroon',
            'Farg\'ona, Uzbekistan',
            'Fargo-Valley City ND Media Market, USA',
            'Fayette County, Kentucky, USA',
            'Fayetteville MSA, NC, USA',
            'Fayetteville [Urban Area], NC, USA',
            'Fayetteville-Springdale-Rogers MSA, AR-MO, USA',
            'Fayetteville-Springdale-Rogers [Urban Area], AR-MO, USA',
            'Finland',
            'Flint MSA, MI, USA',
            'Flint [Urban Area], MI, USA',
            'Flint-Saginaw-Bay City MI Media Market, USA',
            'Florida, USA',
            'Forsyth County, Georgia, USA',
            'Forsyth County, North Carolina, USA',
            'Fort Bend County, Texas, USA',
            'Fort Collins MSA, CO, USA',
            'Fort Collins [Urban Area], CO, USA',
            'Fort Smith MSA, AR-OK, USA',
            'Fort Wayne MSA, IN, USA',
            'Fort Wayne [Urban Area], IN, USA',
            'Fort Wayne city, Indiana, USA',
            'Fort Worth city, Texas, USA',
            'Fortaleza Urban Center, Brazil',
            'France',
            'Franklin County, Ohio, USA',
            'Fraser Valley Regional district, British Columbia, Canada',
            'Frederick County, Maryland, USA',
            'Free State, South Africa',
            'Fresno County, California, USA',
            'Fresno MSA, CA, USA',
            'Fresno Urban Center, USA',
            'Fresno [Urban Area], CA, USA',
            'Fresno city, California, USA',
            'Fresno-Visalia CA Media Market, USA',
            'Ft. Myers-Naples FL Media Market, USA',
            'Ft. Smith-Fayetteville-Springdale-Rogers AR Media Market, USA',
            'Ft. Wayne IN Media Market, USA',
            'Fujian Sheng, China',
            'Fulton County, Georgia, USA',
            'Fuzhou (Fujian Sheng) Urban Center, China',
            'F\u00e8s-Mekn\u00e8s, Morocco',
            'F\u0101rs, Iran',
            'GA-01, USA',
            'GA-02, USA',
            'GA-03, USA',
            'GA-04, USA',
            'GA-05, USA',
            'GA-06, USA',
            'GA-07, USA',
            'GA-08, USA',
            'GA-09, USA',
            'GA-10, USA',
            'GA-11, USA',
            'GA-12, USA',
            'GA-13, USA',
            'GA-14, USA',
            'Gainesville FL Media Market, USA',
            'Gainesville MSA, FL, USA',
            'Gainesville [Urban Area], GA, USA',
            'Galveston County, Texas, USA',
            'Gansu Sheng, China',
            'Gatineau Territory, Quebec, Canada',
            'Gatineau Ville, Gatineau Territory, Quebec, Canada',
            'Gauteng, South Africa',
            'Geita, Tanzania',
            'Genesee County, Michigan, USA',
            'Georgia',
            'Georgia, USA',
            'Germany',
            'Ghana',
            'Gilbert town, Arizona, USA',
            'Gloucester County, New Jersey, USA',
            'Goi\u00e1s, Brazil',
            'Gombe, Nigeria',
            'Grand Est, France',
            'Grand Rapids Urban Center, USA',
            'Grand Rapids [Urban Area], MI, USA',
            'Grand Rapids-Kalamazoo-Battle Creek MI Media Market, USA',
            'Grand Rapids-Wyoming MSA, MI, USA',
            'Greater Accra, Ghana',
            'Greater Bridgeport Planning Region, Connecticut, USA',
            'Greater Vancouver Regional district, British Columbia, Canada',
            'Greece',
            'Greeley MSA, CO, USA',
            'Green Bay MSA, WI, USA',
            'Green Bay-Appleton WI Media Market, USA',
            'Greene County, Missouri, USA',
            'Greensboro [Urban Area], NC, USA',
            'Greensboro city, North Carolina, USA',
            'Greensboro-High Point MSA, NC, USA',
            'Greensboro-High Point-Winston Salem NC Media Market, USA',
            'Greenville County, South Carolina, USA',
            'Greenville [Urban Area], SC, USA',
            'Greenville-Anderson-Mauldin MSA, SC, USA',
            'Greenville-New Bern-Washington NC Media Market, USA',
            'Greenville-Spartanburg SC-Asheville NC-Anderson SC Media Market, USA',
            'Guadalajara Urban Center, Mexico',
            'Guanajuato, Mexico',
            'Guangdong Sheng, China',
            'Guangxi Zhuangzu Zizhiqu, China',
            'Guangzhou Urban Center, China',
            'Guatemala',
            'Guatemala City Urban Center, Guatemala',
            'Guatemala, Guatemala',
            'Guayaquil Urban Center, Ecuador',
            'Guayas, Ecuador',
            'Guerrero, Mexico',
            'Guilford County, North Carolina, USA',
            'Guinea',
            'Guiyang (Guizhou Sheng) Urban Center, China',
            'Guizhou Sheng, China',
            'Gujar\u0101t, India',
            'Gujranwala Urban Center, Pakistan',
            'Gulfport-Biloxi-Pascagoula MSA, MS, USA',
            'Gwinnett County, Georgia, USA',
            'Gyeonggi-do, South Korea',
            'Gyeongsangbuk-do, South Korea',
            'Gyeongsangnam-do, South Korea',
            'HI-01, USA',
            'HI-02, USA',
            'HaMerkaz, Israel',
            'Hagerstown-Martinsburg MSA, MD-WV, USA',
            'Hainan Sheng, China',
            'Haiti',
            'Halifax CMA, NS, Canada',
            'Halifax County, Nova Scotia, Canada',
            'Halifax Population Center, NS, Canada',
            'Halifax Regional Municipality, Halifax County, Nova Scotia, Canada',
            'Halton Regional municipality, Ontario, Canada',
            'Hamgy\u014fng-namdo [Hamkyeongnamto], North Korea',
            'Hamilton CDR, Ontario, Canada',
            'Hamilton CMA, ON, Canada',
            'Hamilton City, Hamilton CDR, Ontario, Canada',
            'Hamilton County, Indiana, USA',
            'Hamilton County, Ohio, USA',
            'Hamilton County, Tennessee, USA',
            'Hamilton Population Center, ON, Canada',
            'Hampden County, Massachusetts, USA',
            'Hangzhou Urban Center, China',
            'Hanoi Urban Center, Vietnam',
            'Harbin Urban Center, China',
            'Harford County, Maryland, USA',
            'Harlingen-Weslaco-Brownsville-Mcallen TX Media Market, USA',
            'Harris County, Texas, USA',
            'Harrisburg [Urban Area], PA, USA',
            'Harrisburg-Carlisle MSA, PA, USA',
            'Harrisburg-Lancaster-Lebanon-York PA Media Market, USA',
            'Harrisonburg VA Media Market, USA',
            'Hartford & New Haven CT Media Market, USA',
            'Hartford Urban Center, USA',
            'Hartford [Urban Area], CT, USA',
            'Hartford-West Hartford-East Hartford MSA, CT, USA',
            'Hary\u0101na, India',
            'Hattiesburg-Laurel MS Media Market, USA',
            'Haut-Katanga, Democratic Republic of the Congo',
            'Haut-Lomami, Democratic Republic of the Congo',
            'Hauts-Bassins, Burkina Faso',
            'Hauts-de-France, France',
            'Hawaii, USA',
            'Hebei Sheng, China',
            'Hefei Urban Center, China',
            'Heilongjiang Sheng, China',
            'Henan Sheng, China',
            'Henderson city, Nevada, USA',
            'Hennepin County, Minnesota, USA',
            'Henrico County, Virginia, USA',
            'Heroica Nogales Urban Center, Mexico-USA',
            'Her\u0101t, Afghanistan',
            'Hessen, Germany',
            'Hickory-Lenoir-Morganton MSA, NC, USA',
            'Hidalgo County, Texas, USA',
            'Hidalgo, Mexico',
            'Hillsborough County, Florida, USA',
            'Hillsborough County, New Hampshire, USA',
            'Him\u0101chal Pradesh, India',
            'Hirosima, Japan',
            'Ho Chi Minh City Urban Center, Vietnam',
            'Hokkaid\u00f4, Japan',
            'Honduras',
            'Hong Kong Urban Center, China',
            'Hong Kong, China',
            'Honolulu County, Hawaii, USA',
            'Honolulu HI Media Market, USA',
            'Honolulu Urban Center, USA',
            'Honolulu [Urban Area], HI, USA',
            'Horry County, South Carolina, USA',
            'Houston TX Media Market, USA',
            'Houston Urban Center, USA',
            'Houston [Urban Area], TX, USA',
            'Houston city, Texas, USA',
            'Houston-The Woodlands-Sugar Land MSA, TX, USA',
            'Howard County, Maryland, USA',
            'Hubei Sheng, China',
            'Hudson County, New Jersey, USA',
            'Hukuoka, Japan',
            'Hunan Sheng, China',
            'Hungary',
            'Huntington-Ashland MSA, WV-KY-OH, USA',
            'Huntsville MSA, AL, USA',
            'Huntsville [Urban Area], AL, USA',
            'Huntsville-Decatur (Florence) AL Media Market, USA',
            'Hu\u00edla, Angola',
            'Hwanghae-namdo [Hwanghainamto], North Korea',
            'Hyderabad Urban Center, India',
            'Hyderabad Urban Center, Pakistan',
            'Hy\u00f4go, Japan',
            'H\u00e0 N\u1ed9i, Vietnam',
            'H\u1ed3 Ch\u00ed Minh, Vietnam',
            'IA-01, USA',
            'IA-02, USA',
            'IA-03, USA',
            'IA-04, USA',
            'ID-01, USA',
            'ID-02, USA',
            'IL-01, USA',
            'IL-02, USA',
            'IL-03, USA',
            'IL-04, USA',
            'IL-05, USA',
            'IL-06, USA',
            'IL-07, USA',
            'IL-08, USA',
            'IL-09, USA',
            'IL-10, USA',
            'IL-11, USA',
            'IL-12, USA',
            'IL-13, USA',
            'IL-14, USA',
            'IL-15, USA',
            'IL-16, USA',
            'IL-17, USA',
            'IN-01, USA',
            'IN-02, USA',
            'IN-03, USA',
            'IN-04, USA',
            'IN-05, USA',
            'IN-06, USA',
            'IN-07, USA',
            'IN-08, USA',
            'IN-09, USA',
            'Ibadan Urban Center, Nigeria',
            'Ibaraki, Japan',
            'Ibb, Yemen',
            'Idaho Falls-Pocatello ID Media Market, USA',
            'Idaho, USA',
            'Illinois, USA',
            'Iloilo, Philippines',
            'Imo, Nigeria',
            'Incheon-gwangyeoksi, South Korea',
            'India',
            'Indiana, USA',
            'Indianapolis IN Media Market, USA',
            'Indianapolis Urban Center, USA',
            'Indianapolis [Urban Area], IN, USA',
            'Indianapolis city (balance), Indiana, USA',
            'Indianapolis-Carmel-Anderson MSA, IN, USA',
            'Indio Urban Center, USA',
            'Indio-Palm Desert-Palm Springs [Urban Area], CA, USA',
            'Indonesia',
            'Indore Urban Center, India',
            'Ingham County, Michigan, USA',
            'Iowa, USA',
            'Iran',
            'Iraq',
            'Ireland',
            'Irvine city, California, USA',
            'Irving city, Texas, USA',
            'Israel',
            'Istanbul Urban Center, Turkey',
            'Italy',
            'Ituri, Democratic Republic of the Congo',
            'Ivory Coast',
            'Izmir Urban Center, Turkey',
            'Jackson County, Missouri, USA',
            'Jackson MS Media Market, USA',
            'Jackson MSA, MS, USA',
            'Jackson [Urban Area], MS, USA',
            'Jacksonville FL Media Market, USA',
            'Jacksonville MSA, FL, USA',
            'Jacksonville Urban Center, USA',
            'Jacksonville [Urban Area], FL, USA',
            'Jacksonville city, Florida, USA',
            'Jaipur Urban Center, India',
            'Jakarta Raya, Indonesia',
            'Jakarta Urban Center, Indonesia',
            'Jalisco, Mexico',
            'Jamaica',
            'Jambi, Indonesia',
            'Jammu and Kashm\u012br, India',
            'Jan\u016bb D\u0101rf\u016br, Sudan',
            'Japan',
            'Jawa Barat, Indonesia',
            'Jawa Tengah, Indonesia',
            'Jawa Timur, Indonesia',
            'Jeddah Urban Center, Saudi Arabia',
            'Jefferson County, Alabama, USA',
            'Jefferson County, Colorado, USA',
            'Jefferson County, Kentucky, USA',
            'Jefferson County, Texas, USA',
            'Jefferson Parish, Louisiana, USA',
            'Jersey City city, New Jersey, USA',
            'Jh\u0101rkhand, India',
            'Jiangsu Sheng, China',
            'Jiangxi Sheng, China',
            'Jieyang Urban Center, China',
            'Jigawa, Nigeria',
            'Jilin Sheng, China',
            'Jinan Urban Center, China',
            'Johannesburg Urban Center, South Africa',
            'Johnson County, Kansas, USA',
            'Johnstown-Altoona PA Media Market, USA',
            'Johor, Malaysia',
            'Joplin MO-Pittsburg KS Media Market, USA',
            'Jordan',
            'KS-01, USA',
            'KS-02, USA',
            'KS-03, USA',
            'KS-04, USA',
            'KY-01, USA',
            'KY-02, USA',
            'KY-03, USA',
            'KY-04, USA',
            'KY-05, USA',
            'KY-06, USA',
            'Kabul Urban Center, Afghanistan',
            'Kaduna, Nigeria',
            'Kafr ash Shaykh, Egypt',
            'Kagera, Tanzania',
            'Kalamazoo County, Michigan, USA',
            'Kalamazoo-Portage MSA, MI, USA',
            'Kalimantan Barat, Indonesia',
            'Kalimantan Selatan, Indonesia',
            'Kalimantan Tengah, Indonesia',
            'Kalimantan Timur, Indonesia',
            'Kampala Urban Center, Uganda',
            'Kanagawa, Japan',
            'Kane County, Illinois, USA',
            'Kankan, Guinea',
            'Kano Urban Center, Nigeria',
            'Kano, Nigeria',
            'Kanpur Urban Center, India',
            'Kansas City MO Media Market, USA',
            'Kansas City MSA, MO-KS, USA',
            'Kansas City Urban Center, USA',
            'Kansas City [Urban Area], MO-KS, USA',
            'Kansas City city, Missouri, USA',
            'Kansas, USA',
            'Karachi Urban Center, Pakistan',
            'Karn\u0101taka, India',
            'Kasa\u00ef Central, Democratic Republic of the Congo',
            'Kasa\u00ef Oriental, Democratic Republic of the Congo',
            'Kasa\u00ef, Democratic Republic of the Congo',
            'Kathmandu Urban Center, Nepal',
            'Katsina, Nigeria',
            'Kayes, Mali',
            'Kazakhstan',
            'Kebbi, Nigeria',
            'Kemerovskaya oblast\', Russia',
            'Kennewick-Richland MSA, WA, USA',
            'Kennewick-Richland-Pasco [Urban Area], WA, USA',
            'Kent County, Michigan, USA',
            'Kentucky, USA',
            'Kenya',
            'Kerala, India',
            'Kerm\u0101n, Iran',
            'Kern County, California, USA',
            'Kharkivska oblast, Ukraine',
            'Khartoum Urban Center, Sudan',
            'Khatlon, Tajikistan',
            'Khor\u0101s\u0101n-e Ra\u1e95av\u012b, Iran',
            'Khulna, Bangladesh',
            'Khyber Pakhtunkhwa, Pakistan',
            'Kh\u016bzest\u0101n, Iran',
            'Kigoma, Tanzania',
            'Killeen [Urban Area], TX, USA',
            'Killeen-Temple MSA, TX, USA',
            'Kindia, Guinea',
            'King County, Washington, USA',
            'Kings County, New York, USA',
            'Kingsport-Bristol-Bristol MSA, TN-VA, USA',
            'Kinshasa Urban Center, Democratic Republic of the Congo',
            'Kinshasa, Democratic Republic of the Congo',
            'Kissimmee-St. Cloud [Urban Area], FL, USA',
            'Kitchener - Cambridge - Waterloo CMA, ON, Canada',
            'Kitchener City, Waterloo Regional municipality, Ontario, Canada',
            'Kitchener Population Center, ON, Canada',
            'Kitchener Urban Center, Canada',
            'Kitsap County, Washington, USA',
            'Knox County, Tennessee, USA',
            'Knoxville MSA, TN, USA',
            'Knoxville TN Media Market, USA',
            'Knoxville [Urban Area], TN, USA',
            'Kochi Urban Center, India',
            'Kogi, Nigeria',
            'Kolkata Urban Center, India',
            'Kollam Urban Center, India',
            'Kongo Central, Democratic Republic of the Congo',
            'Koshi, Nepal',
            'Koulikoro, Mali',
            'Kozhikode Urban Center, India',
            'Krasnodyarskiy kray, Russia',
            'Krasnoyarskiy kray, Russia',
            'Krung Thep Maha Nakhon, Thailand',
            'Kuala Lumpur Urban Center, Malaysia',
            'Kumasi Urban Center, Ghana',
            'Kunming Urban Center, China',
            'Kuwait',
            'Kuwait City Urban Center, Kuwait',
            'Kwara, Nigeria',
            'Kwazulu-Natal, South Africa',
            'Kwilu, Democratic Republic of the Congo',
            'Kyiv Urban Center, Ukraine',
            'Kyiv, Ukraine',
            'Kyrgyzstan',
            'K\u0101bul, Afghanistan',
            'LA-01, USA',
            'LA-02, USA',
            'LA-03, USA',
            'LA-04, USA',
            'LA-05, USA',
            'LA-06, USA',
            'La Crosse-Eau Claire WI Media Market, USA',
            'La Paz, Bolivia',
            'Lafayette LA Media Market, USA',
            'Lafayette MSA, LA, USA',
            'Lagos Urban Center, Nigeria',
            'Lagos, Nigeria',
            'Laguna, Philippines',
            'Lahore Urban Center, Pakistan',
            'Lake Charles LA Media Market, USA',
            'Lake County, Florida, USA',
            'Lake County, Illinois, USA',
            'Lake County, Indiana, USA',
            'Lakeland [Urban Area], FL, USA',
            'Lakeland-Winter Haven MSA, FL, USA',
            'Lampung, Indonesia',
            'Lancaster County, Nebraska, USA',
            'Lancaster County, Pennsylvania, USA',
            'Lancaster MSA, PA, USA',
            'Lancaster-Manheim [Urban Area], PA, USA',
            'Lane County, Oregon, USA',
            'Lansing MI Media Market, USA',
            'Lansing [Urban Area], MI, USA',
            'Lansing-East Lansing MSA, MI, USA',
            'Lanzhou Urban Center, China',
            'Laos',
            'Laredo MSA, TX, USA',
            'Laredo TX Media Market, USA',
            'Laredo [Urban Area], TX, USA',
            'Laredo city, Texas, USA',
            'Larimer County, Colorado, USA',
            'Las Vegas NV Media Market, USA',
            'Las Vegas Urban Center, USA',
            'Las Vegas city, Nevada, USA',
            'Las Vegas-Henderson-Paradise MSA, NV, USA',
            'Las Vegas-Henderson-Paradise [Urban Area], NV, USA',
            'Laval Territory, Quebec, Canada',
            'Laval Ville, Laval Territory, Quebec, Canada',
            'Lazio, Italy',
            'Lebanon',
            'Lee County, Florida, USA',
            'Lehigh County, Pennsylvania, USA',
            'Leinster, Ireland',
            'Leon County, Florida, USA',
            'Lexington County, South Carolina, USA',
            'Lexington KY Media Market, USA',
            'Lexington Urban Center, USA',
            'Lexington-Fayette MSA, KY, USA',
            'Lexington-Fayette [Urban Area], KY, USA',
            'Lexington-Fayette urban county, Kentucky, USA',
            'Liaoning Sheng, China',
            'Liberia',
            'Libya',
            'Lilongwe, Malawi',
            'Lima Urban Center, Peru',
            'Limpopo, South Africa',
            'Lincoln & Hastings-Kearney NE Media Market, USA',
            'Lincoln MSA, NE, USA',
            'Lincoln [Urban Area], NE, USA',
            'Lincoln city, Nebraska, USA',
            'Lithuania',
            'Little Rock [Urban Area], AR, USA',
            'Little Rock-North Little Rock-Conway MSA, AR, USA',
            'Little Rock-Pine Bluff AR Media Market, USA',
            'Littoral, Cameroon',
            'Lomami, Democratic Republic of the Congo',
            'Lombardia, Italy',
            'London CMA, ON, Canada',
            'London City, Middlesex County, Ontario, Canada',
            'London Population Center, ON, Canada',
            'London Urban Center, Canada',
            'London Urban Center, United Kingdom',
            'Long Beach city, California, USA',
            'Longueuil Territory, Quebec, Canada',
            'Longueuil Ville, Longueuil Territory, Quebec, Canada',
            'Lorain County, Ohio, USA',
            'Los Angeles CA Media Market, USA',
            'Los Angeles County, California, USA',
            'Los Angeles Urban Center, USA',
            'Los Angeles city, California, USA',
            'Los Angeles-Long Beach-Anaheim MSA, CA, USA',
            'Los Angeles-Long Beach-Anaheim [Urban Area], CA, USA',
            'Loudoun County, Virginia, USA',
            'Louisiana, USA',
            'Louisville KY Media Market, USA',
            'Louisville Urban Center, USA',
            'Louisville/Jefferson County MSA, KY-IN, USA',
            'Louisville/Jefferson County [Urban Area], KY-IN, USA',
            'Louisville/Jefferson County metro government (balance), Kentucky, USA',
            'Lualaba, Democratic Republic of the Congo',
            'Luanda Urban Center, Angola',
            'Luanda, Angola',
            'Lubbock County, Texas, USA',
            'Lubbock MSA, TX, USA',
            'Lubbock TX Media Market, USA',
            'Lubbock [Urban Area], TX, USA',
            'Lubbock city, Texas, USA',
            'Lucas County, Ohio, USA',
            'Lucknow Urban Center, India',
            'Lumbin\u012b, Nepal',
            'Lusaka Urban Center, Zambia',
            'Lusaka, Zambia',
            'Luzerne County, Pennsylvania, USA',
            'Lynchburg MSA, VA, USA',
            'MA-01, USA',
            'MA-02, USA',
            'MA-03, USA',
            'MA-04, USA',
            'MA-05, USA',
            'MA-06, USA',
            'MA-07, USA',
            'MA-08, USA',
            'MA-09, USA',
            'MD-01, USA',
            'MD-02, USA',
            'MD-03, USA',
            'MD-04, USA',
            'MD-05, USA',
            'MD-06, USA',
            'MD-07, USA',
            'MD-08, USA',
            'ME-01, USA',
            'ME-02, USA',
            'MI-01, USA',
            'MI-02, USA',
            'MI-03, USA',
            'MI-04, USA',
            'MI-05, USA',
            'MI-06, USA',
            'MI-07, USA',
            'MI-08, USA',
            'MI-09, USA',
            'MI-10, USA',
            'MI-11, USA',
            'MI-12, USA',
            'MI-13, USA',
            'MN-01, USA',
            'MN-02, USA',
            'MN-03, USA',
            'MN-04, USA',
            'MN-05, USA',
            'MN-06, USA',
            'MN-07, USA',
            'MN-08, USA',
            'MO-01, USA',
            'MO-02, USA',
            'MO-03, USA',
            'MO-04, USA',
            'MO-05, USA',
            'MO-06, USA',
            'MO-07, USA',
            'MO-08, USA',
            'MS-01, USA',
            'MS-02, USA',
            'MS-03, USA',
            'MS-04, USA',
            'MT-01, USA',
            'MT-02, USA',
            'Macomb County, Michigan, USA',
            'Macon GA Media Market, USA',
            'Madagascar',
            'Madhesh, Nepal',
            'Madhya Pradesh, India',
            'Madhyama pa\u1e37\u0101ta, Sri Lanka',
            'Madison County, Alabama, USA',
            'Madison County, Illinois, USA',
            'Madison MSA, WI, USA',
            'Madison Urban Center, USA',
            'Madison WI Media Market, USA',
            'Madison [Urban Area], WI, USA',
            'Madison city, Wisconsin, USA',
            'Madrid Urban Center, Spain',
            'Madrid, Comunidad de, Spain',
            'Magway, Myanmar',
            'Mah\u0101r\u0101shtra, India',
            'Maine, USA',
            'Makkah al Mukarramah, Saudi Arabia',
            'Malawi',
            'Malaysia',
            'Mali',
            'Manatee County, Florida, USA',
            'Manchester-Nashua MSA, NH, USA',
            'Mandalay, Myanmar',
            'Manipur, India',
            'Manitoba, Canada',
            'Maputo (City), Mozambique',
            'Maputo Urban Center, Mozambique',
            'Maradi, Niger',
            'Maranh\u00e3o, Brazil',
            'Maricopa County, Arizona, USA',
            'Marin County, California, USA',
            'Marion County, Florida, USA',
            'Marion County, Indiana, USA',
            'Marion County, Oregon, USA',
            'Maritime (R\u00e9gion), Togo',
            'Markham City, York Regional municipality, Ontario, Canada',
            'Marrakech-Safi, Morocco',
            'Maryland, USA',
            'Mashhad Urban Center, Iran',
            'Massachusetts, USA',
            'Mato Grosso do Sul, Brazil',
            'Mato Grosso, Brazil',
            'Mauritania',
            'Mazowieckie, Poland',
            'Ma\u0142opolskie, Poland',
            'Mbuji-Mayi Urban Center, Democratic Republic of the Congo',
            'McAllen [Urban Area], TX, USA',
            'McAllen-Edinburg-Mission MSA, TX, USA',
            'McHenry County, Illinois, USA',
            'McKinney-Frisco [Urban Area], TX, USA',
            'McLennan County, Texas, USA',
            'Mecklenburg County, North Carolina, USA',
            'Medan Urban Center, Indonesia',
            'Medell\u00edn Urban Center, Colombia',
            'Medford-Klamath Falls OR Media Market, USA',
            'Megh\u0101laya, India',
            'Melbourne Urban Center, Australia',
            'Memphis MSA, TN-MS-AR, USA',
            'Memphis TN Media Market, USA',
            'Memphis Urban Center, USA',
            'Memphis [Urban Area], TN-MS-AR, USA',
            'Memphis city, Tennessee, USA',
            'Merced County, California, USA',
            'Merced MSA, CA, USA',
            'Mercer County, New Jersey, USA',
            'Mesa city, Arizona, USA',
            'Mexicali Urban Center, Mexico-USA',
            'Mexico',
            'Mexico City Urban Center, Mexico',
            'Miami Urban Center, USA',
            'Miami city, Florida, USA',
            'Miami-Dade County, Florida, USA',
            'Miami-Fort Lauderdale [Urban Area], FL, USA',
            'Miami-Fort Lauderdale-West Palm Beach MSA, FL, USA',
            'Miami-Ft. Lauderdale FL Media Market, USA',
            'Michigan, USA',
            'Michoac\u00e1n de Ocampo, Mexico',
            'Middlesex County, Massachusetts, USA',
            'Middlesex County, New Jersey, USA',
            'Middlesex County, Ontario, Canada',
            'Milan Urban Center, Italy',
            'Milwaukee County, Wisconsin, USA',
            'Milwaukee Urban Center, USA',
            'Milwaukee WI Media Market, USA',
            'Milwaukee [Urban Area], WI, USA',
            'Milwaukee city, Wisconsin, USA',
            'Milwaukee-Waukesha-West Allis MSA, WI, USA',
            'Minas Gerais, Brazil',
            'Minneapolis [Saint Paul] Urban Center, USA',
            'Minneapolis city, Minnesota, USA',
            'Minneapolis-St. Paul MN Media Market, USA',
            'Minneapolis-St. Paul [Urban Area], MN, USA',
            'Minneapolis-St. Paul-Bloomington MSA, MN-WI, USA',
            'Minnesota, USA',
            'Minot-Bismarck-Dickinson ND Media Market, USA',
            'Mission Viejo-Lake Forest-Laguna Niguel [Urban Area], CA, USA',
            'Mississauga City, Peel Regional municipality, Ontario, Canada',
            'Mississippi, USA',
            'Missoula MT Media Market, USA',
            'Missouri, USA',
            'Miyagi, Japan',
            'Mobile AL-Pensacola (Ft. Walton Beach) FL Media Market, USA',
            'Mobile County, Alabama, USA',
            'Mobile MSA, AL, USA',
            'Mobile [Urban Area], AL, USA',
            'Modesto MSA, CA, USA',
            'Modesto Urban Center, USA',
            'Modesto [Urban Area], CA, USA',
            'Mogadishu Urban Center, Somalia',
            'Moldova',
            'Mongolia',
            'Monmouth County, New Jersey, USA',
            'Monroe County, New York, USA',
            'Monroe LA-El Dorado AR Media Market, USA',
            'Montagnes, Ivory Coast',
            'Montana, USA',
            'Monterey County, California, USA',
            'Monterey-Salinas CA Media Market, USA',
            'Monterrey Urban Center, Mexico',
            'Montgomery (Selma) AL Media Market, USA',
            'Montgomery County, Maryland, USA',
            'Montgomery County, Ohio, USA',
            'Montgomery County, Pennsylvania, USA',
            'Montgomery County, Texas, USA',
            'Montgomery MSA, AL, USA',
            'Montgomery [Urban Area], AL, USA',
            'Montreal Urban Center, Canada',
            'Montr\u00e9al CMA, QC, Canada',
            'Montr\u00e9al Population Center, QC, Canada',
            'Montr\u00e9al Territory, Quebec, Canada',
            'Montr\u00e9al Ville, Montr\u00e9al Territory, Quebec, Canada',
            'Mopti, Mali',
            'Morocco',
            'Morogoro, Tanzania',
            'Morris County, New Jersey, USA',
            'Moscow Urban Center, Russia',
            'Moskovskaya oblast\', Russia',
            'Moskva, Russia',
            'Mozambique',
            'Mpumalanga, South Africa',
            'Multan Urban Center, Pakistan',
            'Multnomah County, Oregon, USA',
            'Mumbai Urban Center, India',
            'Municipalidad Metropolitana de Lima, Peru',
            'Mwanza, Tanzania',
            'Myanmar',
            'Mymensingh, Bangladesh',
            'Myrtle Beach-Conway-North Myrtle Beach MSA, SC-NC, USA',
            'Myrtle Beach-Florence SC Media Market, USA',
            'Myrtle Beach-North Myrtle Beach [Urban Area], SC-NC, USA',
            'M\u00e9xico, Mexico',
            'M\u0101zandar\u0101n, Iran',
            'NC-01, USA',
            'NC-02, USA',
            'NC-03, USA',
            'NC-04, USA',
            'NC-05, USA',
            'NC-06, USA',
            'NC-07, USA',
            'NC-08, USA',
            'NC-09, USA',
            'NC-10, USA',
            'NC-11, USA',
            'NC-12, USA',
            'NC-13, USA',
            'NC-14, USA',
            'ND-00, USA',
            'NE-01, USA',
            'NE-02, USA',
            'NE-03, USA',
            'NH-01, USA',
            'NH-02, USA',
            'NJ-01, USA',
            'NJ-02, USA',
            'NJ-03, USA',
            'NJ-04, USA',
            'NJ-05, USA',
            'NJ-06, USA',
            'NJ-07, USA',
            'NJ-08, USA',
            'NJ-09, USA',
            'NJ-10, USA',
            'NJ-11, USA',
            'NJ-12, USA',
            'NM-01, USA',
            'NM-02, USA',
            'NM-03, USA',
            'NV-01, USA',
            'NV-02, USA',
            'NV-03, USA',
            'NV-04, USA',
            'NY-01, USA',
            'NY-02, USA',
            'NY-03, USA',
            'NY-04, USA',
            'NY-05, USA',
            'NY-06, USA',
            'NY-07, USA',
            'NY-08, USA',
            'NY-09, USA',
            'NY-10, USA',
            'NY-11, USA',
            'NY-12, USA',
            'NY-13, USA',
            'NY-14, USA',
            'NY-15, USA',
            'NY-16, USA',
            'NY-17, USA',
            'NY-18, USA',
            'NY-19, USA',
            'NY-20, USA',
            'NY-21, USA',
            'NY-22, USA',
            'NY-23, USA',
            'NY-24, USA',
            'NY-25, USA',
            'NY-26, USA',
            'Nagoya Urban Center, Japan',
            'Nagpur Urban Center, India',
            'Nairobi City, Kenya',
            'Nairobi Urban Center, Kenya',
            'Namangan, Uzbekistan',
            'Nampula, Mozambique',
            'Nanchang (Northern Jiangxi Sheng) Urban Center, China',
            'Nanjing (Jiangsu Sheng) Urban Center, China',
            'Nanning Urban Center, China',
            'Naples Urban Center, Italy',
            'Naples-Immokalee-Marco Island MSA, FL, USA',
            'Nasarawa, Nigeria',
            'Nashville TN Media Market, USA',
            'Nashville Urban Center, USA',
            'Nashville-Davidson [Urban Area], TN, USA',
            'Nashville-Davidson metropolitan government (balance), Tennessee, USA',
            'Nashville-Davidson--Murfreesboro--Franklin MSA, TN, USA',
            'Nassau County, New York, USA',
            'National Capital Region, Philippines',
            'Naugatuck Valley Planning Region, Connecticut, USA',
            'Nebraska, USA',
            'Negros Occidental, Philippines',
            'Nei Mongol Zizhiqu, China',
            'Nepal',
            'Netherlands',
            'Nevada, USA',
            'New Brunswick, Canada',
            'New Castle County, Delaware, USA',
            'New Hampshire, USA',
            'New Haven [Urban Area], CT, USA',
            'New Haven-Milford MSA, CT, USA',
            'New Jersey, USA',
            'New Mexico, USA',
            'New Orleans LA Media Market, USA',
            'New Orleans Urban Center, USA',
            'New Orleans [Urban Area], LA, USA',
            'New Orleans city, Louisiana, USA',
            'New Orleans-Metairie MSA, LA, USA',
            'New South Wales, Australia',
            'New Taipei [Taipei] Urban Center, China',
            'New York County, New York, USA',
            'New York NY Media Market, USA',
            'New York Urban Center, USA',
            'New York city, New York, USA',
            'New York, USA',
            'New York-Jersey City-Newark [Urban Area], NY-NJ, USA',
            'New York-Newark-Jersey City MSA, NY-NJ-PA, USA',
            'New Zealand',
            'Newark city, New Jersey, USA',
            'Newfoundland and Labrador, Canada',
            'Newport News Urban Center, USA',
            'Ngh\u1ec7 An, Vietnam',
            'Niagara Regional municipality, Ontario, Canada',
            'Nicaragua',
            'Niedersachsen, Germany',
            'Niger',
            'Niger, Nigeria',
            'Nigeria',
            'Ningbo Urban Center, China',
            'Ningxia Zizhiiqu, China',
            'Nizhegorodskaya oblast\', Russia',
            'Noord-Brabant, Netherlands',
            'Noord-Holland, Netherlands',
            'Nord-Kivu, Democratic Republic of the Congo',
            'Nordrhein-Westfalen, Germany',
            'Norfolk County, Massachusetts, USA',
            'Norfolk-Portsmouth-Newport News VA Media Market, USA',
            'Normandie, France',
            'North Carolina, USA',
            'North Dakota, USA',
            'North Korea',
            'North Las Vegas city, Nevada, USA',
            'North Port-Sarasota-Bradenton MSA, FL, USA',
            'North, Cameroon',
            'North-West, South Africa',
            'Northampton County, Pennsylvania, USA',
            'Norway',
            'Norwich-New London MSA, CT, USA',
            'Nouvelle-Aquitaine, France',
            'Nova Scotia, Canada',
            'Novosibirskaya oblast\', Russia',
            'Nueces County, Texas, USA',
            'Nuevo Laredo Urban Center, Mexico-USA',
            'Nuevo Le\u00f3n, Mexico',
            'Nusa Tenggara Barat, Indonesia',
            'Nusa Tenggara Timur, Indonesia',
            'N\u012bnaw\u00e1, Iraq',
            'OH-01, USA',
            'OH-02, USA',
            'OH-03, USA',
            'OH-04, USA',
            'OH-05, USA',
            'OH-06, USA',
            'OH-07, USA',
            'OH-08, USA',
            'OH-09, USA',
            'OH-10, USA',
            'OH-11, USA',
            'OH-12, USA',
            'OH-13, USA',
            'OH-14, USA',
            'OH-15, USA',
            'OK-01, USA',
            'OK-02, USA',
            'OK-03, USA',
            'OK-04, USA',
            'OK-05, USA',
            'OR-01, USA',
            'OR-02, USA',
            'OR-03, USA',
            'OR-04, USA',
            'OR-05, USA',
            'OR-06, USA',
            'Oakland County, Michigan, USA',
            'Oakland city, California, USA',
            'Oaxaca, Mexico',
            'Ocala MSA, FL, USA',
            'Occitanie, France',
            'Ocean County, New Jersey, USA',
            'Odessa-Midland TX Media Market, USA',
            'Odisha, India',
            'Ogden Urban Center, USA',
            'Ogden-Clearfield MSA, UT, USA',
            'Ogden-Layton [Urban Area], UT, USA',
            'Ogun, Nigeria',
            'Ohio, USA',
            'Oklahoma City MSA, OK, USA',
            'Oklahoma City OK Media Market, USA',
            'Oklahoma City Urban Center, USA',
            'Oklahoma City [Urban Area], OK, USA',
            'Oklahoma City city, Oklahoma, USA',
            'Oklahoma County, Oklahoma, USA',
            'Oklahoma, USA',
            'Olympia-Tumwater MSA, WA, USA',
            'Omaha NE Media Market, USA',
            'Omaha Urban Center, USA',
            'Omaha [Urban Area], NE-IA, USA',
            'Omaha city, Nebraska, USA',
            'Omaha-Council Bluffs MSA, NE-IA, USA',
            'Oman',
            'Ondo, Nigeria',
            'Onitsha Urban Center, Nigeria',
            'Onondaga County, New York, USA',
            'Ontario, Canada',
            'Orange County, California, USA',
            'Orange County, Florida, USA',
            'Orange County, New York, USA',
            'Oregon, USA',
            'Orlando Urban Center, USA',
            'Orlando [Urban Area], FL, USA',
            'Orlando city, Florida, USA',
            'Orlando-Daytona Beach-Melbourne FL Media Market, USA',
            'Orlando-Kissimmee-Sanford MSA, FL, USA',
            'Orleans Parish, Louisiana, USA',
            'Orom\u012bya, Ethiopia',
            'Osaka [Kyoto] Urban Center, Japan',
            'Osceola County, Florida, USA',
            'Oshawa CMA, ON, Canada',
            'Oshawa Population Center, ON, Canada',
            'Oshawa Urban Center, Canada',
            'Osun, Nigeria',
            'Ottawa - Gatineau CMA, QC-ON, Canada',
            'Ottawa - Gatineau Population Center, QC-ON, Canada',
            'Ottawa CDR, Ontario, Canada',
            'Ottawa City, Ottawa CDR, Ontario, Canada',
            'Ottawa County, Michigan, USA',
            'Ottawa Urban Center, Canada',
            'Ouest, Haiti',
            'Ouest, Rwanda',
            'Oxnard Urban Center, USA',
            'Oxnard-San Buenaventura (Ventura) [Urban Area], CA, USA',
            'Oxnard-Thousand Oaks-Ventura MSA, CA, USA',
            'Oyo, Nigeria',
            'Ozama, Dominican Republic',
            'PA-01, USA',
            'PA-02, USA',
            'PA-03, USA',
            'PA-04, USA',
            'PA-05, USA',
            'PA-06, USA',
            'PA-07, USA',
            'PA-08, USA',
            'PA-09, USA',
            'PA-10, USA',
            'PA-11, USA',
            'PA-12, USA',
            'PA-13, USA',
            'PA-14, USA',
            'PA-15, USA',
            'PA-16, USA',
            'PA-17, USA',
            'Paducah KY-Cape Girardeau MO-Harrisburg IL Media Market, USA',
            'Pakistan',
            'Palm Bay Urban Center, USA',
            'Palm Bay-Melbourne [Urban Area], FL, USA',
            'Palm Bay-Melbourne-Titusville MSA, FL, USA',
            'Palm Beach County, Florida, USA',
            'Palm Springs CA Media Market, USA',
            'Palmdale Urban Center, USA',
            'Palmdale-Lancaster [Urban Area], CA, USA',
            'Pampanga, Philippines',
            'Panama',
            'Panama City FL Media Market, USA',
            'Pangasinan, Philippines',
            'Papua New Guinea',
            'Papua, Indonesia',
            'Paraguay',
            'Paran\u00e1, Brazil',
            'Para\u00edba, Brazil',
            'Paris Urban Center, France',
            'Par\u00e1, Brazil',
            'Pasco County, Florida, USA',
            'Passaic County, New Jersey, USA',
            'Patna Urban Center, India',
            'Pays de la Loire, France',
            'Peel Regional municipality, Ontario, Canada',
            'Pennsylvania, USA',
            'Pensacola [Urban Area], FL-AL, USA',
            'Pensacola-Ferry Pass-Brent MSA, FL, USA',
            'Peoria MSA, IL, USA',
            'Peoria [Urban Area], IL, USA',
            'Peoria-Bloomington IL Media Market, USA',
            'Perak, Malaysia',
            'Permskiy kray, Russia',
            'Pernambuco, Brazil',
            'Peru',
            'Peshawar Urban Center, Pakistan',
            'Philadelphia County, Pennsylvania, USA',
            'Philadelphia PA Media Market, USA',
            'Philadelphia Urban Center, USA',
            'Philadelphia [Urban Area], PA-NJ-DE-MD, USA',
            'Philadelphia city, Pennsylvania, USA',
            'Philadelphia-Camden-Wilmington MSA, PA-NJ-DE-MD, USA',
            'Philippines',
            'Phoenix (Prescott) AZ Media Market, USA',
            'Phoenix Urban Center, USA',
            'Phoenix West-Goodyear-Avondale [Urban Area], AZ, USA',
            'Phoenix city, Arizona, USA',
            'Phoenix-Mesa-Scottsdale MSA, AZ, USA',
            'Phoenix-Mesa-Scottsdale [Urban Area], AZ, USA',
            'Piau\u00ed, Brazil',
            'Pichincha, Ecuador',
            'Piemonte, Italy',
            'Pierce County, Washington, USA',
            'Pima County, Arizona, USA',
            'Pinal County, Arizona, USA',
            'Pinellas County, Florida, USA',
            'Pittsburgh MSA, PA, USA',
            'Pittsburgh PA Media Market, USA',
            'Pittsburgh Urban Center, USA',
            'Pittsburgh [Urban Area], PA, USA',
            'Pittsburgh city, Pennsylvania, USA',
            'Placer County, California, USA',
            'Plano city, Texas, USA',
            'Plateau, Nigeria',
            'Plymouth County, Massachusetts, USA',
            'Poland',
            'Polk County, Florida, USA',
            'Polk County, Iowa, USA',
            'Ponce MSA, PR, USA',
            'Port St. Lucie MSA, FL, USA',
            'Port St. Lucie [Urban Area], FL, USA',
            'Portland (Oregon-Washington) Urban Center, USA',
            'Portland OR Media Market, USA',
            'Portland [Urban Area], OR-WA, USA',
            'Portland city, Oregon, USA',
            'Portland-Auburn ME Media Market, USA',
            'Portland-South Portland MSA, ME, USA',
            'Portland-Vancouver-Hillsboro MSA, OR-WA, USA',
            'Portugal',
            'Poughkeepsie-Newburgh [Urban Area], NY, USA',
            'Prince George\'s County, Maryland, USA',
            'Prince William County, Virginia, USA',
            'Provence-Alpes-C\u00f4te d\'Azur, France',
            'Providence County, Rhode Island, USA',
            'Providence Urban Center, USA',
            'Providence [Urban Area], RI-MA, USA',
            'Providence-Warwick MSA, RI-MA, USA',
            'ProvidenceRi-New Bedford MA Media Market, USA',
            'Provo Urban Center, USA',
            'Provo-Orem MSA, UT, USA',
            'Provo-Orem [Urban Area], UT, USA',
            'Puebla, Mexico',
            'Puerto Rico, USA',
            'Puglia, Italy',
            'Pulaski County, Arkansas, USA',
            'Pune Urban Center, India',
            'Punjab, India',
            'Punjab, Pakistan',
            'Pyongyang Urban Center, North Korea',
            'P\u2019y\u014fngan-bukto [Phyeonganpukto], North Korea',
            'P\u2019y\u014fngan-namdo [Phyeongannamto], North Korea',
            'P\u2019y\u014fngyang [Phyeongyang], North Korea',
            'Qashqadaryo, Uzbekistan',
            'Qatar',
            'Qingdao Urban Center, China',
            'Qinghai Sheng, China',
            'Qin\u0101, Egypt',
            'Quanzhou (Fujian Sheng) Urban Center, China',
            'Quebec Urban Center, Canada',
            'Quebec, Canada',
            'Queens County, New York, USA',
            'Queensland, Australia',
            'Quezon City [Manila] Urban Center, Philippines',
            'QuincyIl-Hannibal MO-Keokuk IA Media Market, USA',
            'Qu\u00e9bec CMA, QC, Canada',
            'Qu\u00e9bec Population Center, QC, Canada',
            'Qu\u00e9bec Territory, Quebec, Canada',
            'Qu\u00e9bec Ville, Qu\u00e9bec Territory, Quebec, Canada',
            'RI-01, USA',
            'RI-02, USA',
            'Rabat-Sal\u00e9-K\u00e9nitra, Morocco',
            'Rajshahi, Bangladesh',
            'Raleigh MSA, NC, USA',
            'Raleigh Urban Center, USA',
            'Raleigh [Urban Area], NC, USA',
            'Raleigh city, North Carolina, USA',
            'Raleigh-Durham (Fayetteville) NC Media Market, USA',
            'Ramsey County, Minnesota, USA',
            'Rangpur, Bangladesh',
            'Rapid City SD Media Market, USA',
            'Rawalpindi [Islamabad] Urban Center, Pakistan',
            'Reading MSA, PA, USA',
            'Reading [Urban Area], PA, USA',
            'Recife Urban Center, Brazil',
            'Regi\u00f3n Metropolitana de Santiago, Chile',
            'Reno MSA, NV, USA',
            'Reno NV Media Market, USA',
            'Reno [Urban Area], NV-CA, USA',
            'Reno city, Nevada, USA',
            'Rheinland-Pfalz, Germany',
            'Rhode Island, USA',
            'Riau, Indonesia',
            'Richland County, South Carolina, USA',
            'Richmond County, New York, USA',
            'Richmond MSA, VA, USA',
            'Richmond Urban Center, USA',
            'Richmond [Urban Area], VA, USA',
            'Richmond-Petersburg VA Media Market, USA',
            'Rio Grande do Norte, Brazil',
            'Rio Grande do Sul, Brazil',
            'Rio de Janeiro Urban Center, Brazil',
            'Rio de Janeiro, Brazil',
            'Rivers, Nigeria',
            'Riverside County, California, USA',
            'Riverside city, California, USA',
            'Riverside-San Bernardino [Urban Area], CA, USA',
            'Riverside-San Bernardino-Ontario MSA, CA, USA',
            'Riyadh Urban Center, Saudi Arabia',
            'Rizal, Philippines',
            'Roanoke MSA, VA, USA',
            'Roanoke-Lynchburg VA Media Market, USA',
            'Rochester (New York) Urban Center, USA',
            'Rochester MN-Mason CityIa-Austin MN Media Market, USA',
            'Rochester MSA, NY, USA',
            'Rochester NY Media Market, USA',
            'Rochester [Urban Area], NY, USA',
            'Rockford IL Media Market, USA',
            'Rockford MSA, IL, USA',
            'Rockford [Urban Area], IL, USA',
            'Rockingham County, New Hampshire, USA',
            'Rockland County, New York, USA',
            'Romania',
            'Rostovskaya oblast\', Russia',
            'Round Lake Beach-McHenry-Grayslake [Urban Area], IL-WI, USA',
            'Russia',
            'Rutherford County, Tennessee, USA',
            'Rwanda',
            'R\u00edo Piedras [San Juan] Urban Center, USA',
            'R\u0101jasth\u0101n, India',
            'R\u012bf Dimashq, Syria',
            'SC-01, USA',
            'SC-02, USA',
            'SC-03, USA',
            'SC-04, USA',
            'SC-05, USA',
            'SC-06, USA',
            'SC-07, USA',
            'SD-00, USA',
            'Sabah, Malaysia',
            'Sachsen, Germany',
            'Sacramento County, California, USA',
            'Sacramento Urban Center, USA',
            'Sacramento [Urban Area], CA, USA',
            'Sacramento city, California, USA',
            'Sacramento--Roseville--Arden-Arcade MSA, CA, USA',
            'Sacramento-Stockton-Modesto CA Media Market, USA',
            'Sagaing, Myanmar',
            'Saint Petersburg Urban Center, Russia',
            'Saitama, Japan',
            'Salem MSA, OR, USA',
            'Salem [Urban Area], OR, USA',
            'Salinas MSA, CA, USA',
            'Salisbury MD Media Market, USA',
            'Salisbury MSA, MD-DE, USA',
            'Salt Lake City MSA, UT, USA',
            'Salt Lake City UT Media Market, USA',
            'Salt Lake City Urban Center, USA',
            'Salt Lake City [Urban Area], UT, USA',
            'Salt Lake County, Utah, USA',
            'Salvador Urban Center, Brazil',
            'Samarqand, Uzbekistan',
            'Samarskaya oblast\', Russia',
            'Samut Prakan, Thailand',
            'San Antonio TX Media Market, USA',
            'San Antonio Urban Center, USA',
            'San Antonio [Urban Area], TX, USA',
            'San Antonio city, Texas, USA',
            'San Antonio-New Braunfels MSA, TX, USA',
            'San Bernardino County, California, USA',
            'San Diego CA Media Market, USA',
            'San Diego County, California, USA',
            'San Diego [Urban Area], CA, USA',
            'San Diego city, California, USA',
            'San Diego-Carlsbad MSA, CA, USA',
            'San Francisco County, California, USA',
            'San Francisco city, California, USA',
            'San Francisco-Oakland [Urban Area], CA, USA',
            'San Francisco-Oakland-Hayward MSA, CA, USA',
            'San Francisco-Oakland-San Jose CA Media Market, USA',
            'San Joaquin County, California, USA',
            'San Jose Urban Center, USA',
            'San Jose [Urban Area], CA, USA',
            'San Jose city, California, USA',
            'San Jose-Sunnyvale-Santa Clara MSA, CA, USA',
            'San Juan Municipio, Puerto Rico, USA',
            'San Juan [Urban Area], PR, USA',
            'San Juan zona urbana, Puerto Rico, USA',
            'San Juan-Carolina-Caguas MSA, PR, USA',
            'San Luis Obispo County, California, USA',
            'San Luis Obispo-Paso Robles-Arroyo Grande MSA, CA, USA',
            'San Luis Potos\u00ed, Mexico',
            'San Mateo County, California, USA',
            'Sana\'a Urban Center, Yemen',
            'Sankt-Peterburg, Russia',
            'Santa Ana city, California, USA',
            'Santa Barbara County, California, USA',
            'Santa Barbara-Santa Maria-San Luis Obispo CA Media Market, USA',
            'Santa Catarina, Brazil',
            'Santa Clara County, California, USA',
            'Santa Clarita [Urban Area], CA, USA',
            'Santa Cruz County, California, USA',
            'Santa Cruz, Bolivia',
            'Santa Cruz-Watsonville MSA, CA, USA',
            'Santa Fe, Argentina',
            'Santa Maria-Santa Barbara MSA, CA, USA',
            'Santa Rosa MSA, CA, USA',
            'Santa Rosa [Urban Area], CA, USA',
            'Santiago Urban Center, Chile',
            'Santo Domingo Urban Center, Dominican Republic',
            'Sarasota County, Florida, USA',
            'Sarawak, Malaysia',
            'Saskatchewan, Canada',
            'Saskatoon CMA, SK, Canada',
            'Saskatoon City, Division No. 11, Saskatchewan, Canada',
            'Saskatoon Population Center, SK, Canada',
            'Sassandra-Marahou\u00e9, Ivory Coast',
            'Saudi Arabia',
            'Savannah GA Media Market, USA',
            'Savannah MSA, GA, USA',
            'Savannah [Urban Area], GA, USA',
            'Schleswig-Holstein, Germany',
            'Scotland, United Kingdom',
            'Scranton [Urban Area], PA, USA',
            'Scranton--Wilkes-Barre--Hazleton MSA, PA, USA',
            'Seattle Urban Center, USA',
            'Seattle city, Washington, USA',
            'Seattle-Tacoma WA Media Market, USA',
            'Seattle-Tacoma [Urban Area], WA, USA',
            'Seattle-Tacoma-Bellevue MSA, WA, USA',
            'Sedgwick County, Kansas, USA',
            'Selangor, Malaysia',
            'Semarang Urban Center, Indonesia',
            'Seminole County, Florida, USA',
            'Senegal',
            'Seoul Urban Center, South Korea',
            'Seoul-teukbyeolsi, South Korea',
            'Serbia',
            'Shaanxi Sheng, China',
            'Sham\u0101l D\u0101rf\u016br, Sudan',
            'Sham\u0101l Kurduf\u0101n, Sudan',
            'Shan, Myanmar',
            'Shandong Sheng, China',
            'Shanghai Shi, China',
            'Shanghai Urban Center, China',
            'Shanxi Sheng, China',
            'Shelby County, Tennessee, USA',
            'Shenyang Urban Center, China',
            'Sherman TX-Ada OK Media Market, USA',
            'Shijiazhuang Urban Center, China',
            'Shreveport LA Media Market, USA',
            'Shreveport [Urban Area], LA, USA',
            'Shreveport-Bossier City MSA, LA, USA',
            'Sichuan Sheng, China',
            'Sicilia, Italy',
            'Sierra Leone',
            'Sikasso, Mali',
            'Simcoe County, Ontario, Canada',
            'Sinaloa, Mexico',
            'Sindh, Pakistan',
            'Singapore',
            'Singapore Urban Center, Malaysia-Singapore',
            'Sioux City IA Media Market, USA',
            'Sioux Falls (Mitchell) SD Media Market, USA',
            'Sioux Falls MSA, SD, USA',
            'Sizuoka, Japan',
            'Slovakia',
            'Snohomish County, Washington, USA',
            'Sokoto, Nigeria',
            'Solano County, California, USA',
            'Somalia',
            'Somerset County, New Jersey, USA',
            'Sonoma County, California, USA',
            'Sonora, Mexico',
            'Souss-Massa, Morocco',
            'South Africa',
            'South Bend [Urban Area], IN-MI, USA',
            'South Bend-Elkhart IN Media Market, USA',
            'South Bend-Mishawaka MSA, IN-MI, USA',
            'South Carolina, USA',
            'South Central Connecticut Planning Region, Connecticut, USA',
            'South Dakota, USA',
            'South Korea',
            'South Sudan',
            'Southeastern Connecticut Planning Region, Connecticut, USA',
            'Spain',
            'Spartanburg County, South Carolina, USA',
            'Spartanburg MSA, SC, USA',
            'Spokane County, Washington, USA',
            'Spokane Urban Center, USA',
            'Spokane WA Media Market, USA',
            'Spokane [Urban Area], WA, USA',
            'Spokane-Spokane Valley MSA, WA, USA',
            'Springfield (Massachusetts) Urban Center, USA',
            'Springfield MO Media Market, USA',
            'Springfield MSA, MA, USA',
            'Springfield MSA, MO, USA',
            'Springfield [Urban Area], MA-CT, USA',
            'Springfield [Urban Area], MO, USA',
            'Springfield-Holyoke MA Media Market, USA',
            'Sri Lanka',
            'St. Catharines - Niagara CMA, ON, Canada',
            'St. Charles County, Missouri, USA',
            'St. Clair County, Illinois, USA',
            'St. Johns County, Florida, USA',
            'St. Joseph County, Indiana, USA',
            'St. Louis County, Missouri, USA',
            'St. Louis MO Media Market, USA',
            'St. Louis MSA, MO-IL, USA',
            'St. Louis Urban Center, USA',
            'St. Louis [Urban Area], MO-IL, USA',
            'St. Louis city, Missouri, USA',
            'St. Louis city-County, Missouri, USA',
            'St. Lucie County, Florida, USA',
            'St. Paul city, Minnesota, USA',
            'St. Petersburg city, Florida, USA',
            'St. Tammany Parish, Louisiana, USA',
            'Stanislaus County, California, USA',
            'Stark County, Ohio, USA',
            'State of Palestine',
            'Stavropol\'skiy kray, Russia',
            'Stockton Urban Center, USA',
            'Stockton [Urban Area], CA, USA',
            'Stockton city, California, USA',
            'Stockton-Lodi MSA, CA, USA',
            'Sud, Rwanda',
            'Sud-Kivu, Democratic Republic of the Congo',
            'Sudan',
            'Sud\u016br Pashchim, Nepal',
            'Suffolk County, Massachusetts, USA',
            'Suffolk County, New York, USA',
            'Sughd, Tajikistan',
            'Sulawesi Selatan, Indonesia',
            'Sulawesi Tengah, Indonesia',
            'Sulawesi Tenggara, Indonesia',
            'Sulawesi Utara, Indonesia',
            'Sumal\u0113, Ethiopia',
            'Sumatera Barat, Indonesia',
            'Sumatera Selatan, Indonesia',
            'Sumatera Utara, Indonesia',
            'Summit County, Ohio, USA',
            'Surabaya Urban Center, Indonesia',
            'Surat Urban Center, India',
            'Surrey City, Greater Vancouver Regional district, British Columbia, Canada',
            'Surxondaryo, Uzbekistan',
            'Suzhou (Jiangsu Sheng) Urban Center, China',
            'Sverdlovskaya oblast\', Russia',
            'Sweden',
            'Switzerland',
            'Sydney Urban Center, Australia',
            'Sylhet, Bangladesh',
            'Syracuse MSA, NY, USA',
            'Syracuse NY Media Market, USA',
            'Syracuse Urban Center, USA',
            'Syracuse [Urban Area], NY, USA',
            'Syria',
            'S\u00e3o Paulo Urban Center, Brazil',
            'S\u00e3o Paulo, Brazil',
            'S\u00e9gou, Mali',
            'S\u012bdama, Ethiopia',
            'S\u012bst\u0101n va Bal\u016bchest\u0101n, Iran',
            'S\u016bh\u0101j, Egypt',
            'TN-01, USA',
            'TN-02, USA',
            'TN-03, USA',
            'TN-04, USA',
            'TN-05, USA',
            'TN-06, USA',
            'TN-07, USA',
            'TN-08, USA',
            'TN-09, USA',
            'TX-01, USA',
            'TX-02, USA',
            'TX-03, USA',
            'TX-04, USA',
            'TX-05, USA',
            'TX-06, USA',
            'TX-07, USA',
            'TX-08, USA',
            'TX-09, USA',
            'TX-10, USA',
            'TX-11, USA',
            'TX-12, USA',
            'TX-13, USA',
            'TX-14, USA',
            'TX-15, USA',
            'TX-16, USA',
            'TX-17, USA',
            'TX-18, USA',
            'TX-19, USA',
            'TX-20, USA',
            'TX-21, USA',
            'TX-22, USA',
            'TX-23, USA',
            'TX-24, USA',
            'TX-25, USA',
            'TX-26, USA',
            'TX-27, USA',
            'TX-28, USA',
            'TX-29, USA',
            'TX-30, USA',
            'TX-31, USA',
            'TX-32, USA',
            'TX-33, USA',
            'TX-34, USA',
            'TX-35, USA',
            'TX-36, USA',
            'TX-37, USA',
            'TX-38, USA',
            'Tabasco, Mexico',
            'Tabora, Tanzania',
            'Tahoua, Niger',
            'Taiwan, China',
            'Taiyuan Urban Center, China',
            'Tajikistan',
            'Tallahassee FL-Thomasville GA Media Market, USA',
            'Tallahassee MSA, FL, USA',
            'Tallahassee [Urban Area], FL, USA',
            'Tamaulipas, Mexico',
            'Tamil N\u0101du, India',
            'Tampa Urban Center, USA',
            'Tampa city, Florida, USA',
            'Tampa-St. Petersburg [Urban Area], FL, USA',
            'Tampa-St. Petersburg-Clearwater MSA, FL, USA',
            'Tampa-St.Petersburg (Sarasota) FL Media Market, USA',
            'Tanga, Tanzania',
            'Tanganyika, Democratic Republic of the Congo',
            'Tanger-T\u00e9touan-Al Hoce\u00efma, Morocco',
            'Tanzania',
            'Taraba, Nigeria',
            'Tarrant County, Texas, USA',
            'Tashkent Urban Center, Uzbekistan',
            'Tasikmalaya Urban Center, Indonesia',
            'Tatarstan, Respublika, Russia',
            'Tehran Urban Center, Iran',
            'Tehr\u0101n, Iran',
            'Tel Aviv Urban Center, Israel',
            'Telang\u0101na, India',
            'Temecula-Murrieta-Menifee [Urban Area], CA, USA',
            'Tennessee, USA',
            'Terre Haute IN Media Market, USA',
            'Tete, Mozambique',
            'Texas, USA',
            'Thailand',
            'Thanh H\u00f3a, Vietnam',
            'The Gambia',
            'The Woodlands-Conroe [Urban Area], TX, USA',
            'Thiruvananthapuram Urban Center, India',
            'Thurston County, Washington, USA',
            'Tianjin Shi, China',
            'Tianjin Urban Center, China',
            'Tiba, Japan',
            'Tigray, Ethiopia',
            'Tijuana Urban Center, Mexico-USA',
            'Tillab\u00e9ri, Niger',
            'Togo',
            'Tokyo Urban Center, Japan',
            'Toledo MSA, OH, USA',
            'Toledo OH Media Market, USA',
            'Toledo [Urban Area], OH-MI, USA',
            'Toledo city, Ohio, USA',
            'Topeka KS Media Market, USA',
            'Toronto CDR, Ontario, Canada',
            'Toronto CMA, ON, Canada',
            'Toronto City, Toronto CDR, Ontario, Canada',
            'Toronto Population Center, ON, Canada',
            'Toronto Urban Center, Canada',
            'Toscana, Italy',
            'Toshkent (Province), Uzbekistan',
            'Traverse City-Cadillac MI Media Market, USA',
            'Travis County, Texas, USA',
            'Trenton MSA, NJ, USA',
            'Trenton [Urban Area], NJ, USA',
            'Tri-Cities TN-VA Media Market, USA',
            'Tripura, India',
            'Tshopo, Democratic Republic of the Congo',
            'Tucson (Sierra Vista) AZ Media Market, USA',
            'Tucson MSA, AZ, USA',
            'Tucson Urban Center, USA',
            'Tucson [Urban Area], AZ, USA',
            'Tucson city, Arizona, USA',
            'Tulare County, California, USA',
            'Tulsa County, Oklahoma, USA',
            'Tulsa MSA, OK, USA',
            'Tulsa OK Media Market, USA',
            'Tulsa Urban Center, USA',
            'Tulsa [Urban Area], OK, USA',
            'Tulsa city, Oklahoma, USA',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Tuscaloosa MSA, AL, USA',
            'Tyler-Longview (Lufkin & Nacogdoches) TX Media Market, USA',
            'T\u00f4ky\u00f4, Japan',
            'T\u0101\u2018izz, Yemen',
            'USA',
            'UT-01, USA',
            'UT-02, USA',
            'UT-03, USA',
            'UT-04, USA',
            'Uganda',
            'Ukraine',
            'Union County, New Jersey, USA',
            'United Arab Emirates',
            'United Kingdom',
            'Urban Honolulu CDP, Hawaii, USA',
            'Urban Honolulu MSA, HI, USA',
            'Uruguay',
            'Utah County, Utah, USA',
            'Utah, USA',
            'Utica NY Media Market, USA',
            'Utica-Rome MSA, NY, USA',
            'Uttar Pradesh, India',
            'Uttar\u0101khand, India',
            'Uzbekistan',
            'VA-01, USA',
            'VA-02, USA',
            'VA-03, USA',
            'VA-04, USA',
            'VA-05, USA',
            'VA-06, USA',
            'VA-07, USA',
            'VA-08, USA',
            'VA-09, USA',
            'VA-10, USA',
            'VA-11, USA',
            'VT-00, USA',
            'Valenciana, Comunidad, Spain',
            'Valle del Cauca, Colombia',
            'Vallejo-Fairfield MSA, CA, USA',
            'Vancouver CMA, BC, Canada',
            'Vancouver City, Greater Vancouver Regional district, British Columbia, Canada',
            'Vancouver Population Center, BC, Canada',
            'Vancouver Urban Center, Canada',
            'Varanasi Urban Center, India',
            'Vaughan City, York Regional municipality, Ontario, Canada',
            'Vayamba pa\u1e37\u0101ta, Sri Lanka',
            'Veneto, Italy',
            'Venezuela',
            'Ventura County, California, USA',
            'Veracruz de Ignacio de la Llave, Mexico',
            'Vermont, USA',
            'Victoria CMA, BC, Canada',
            'Victoria Population Center, BC, Canada',
            'Victoria, Australia',
            'Victorville-Hesperia-Apple Valley [Urban Area], CA, USA',
            'Vietnam',
            'Virginia Beach Urban Center, USA',
            'Virginia Beach city, Virginia, USA',
            'Virginia Beach city-County, Virginia, USA',
            'Virginia Beach-Norfolk [Urban Area], VA, USA',
            'Virginia Beach-Norfolk-Newport News MSA, VA-NC, USA',
            'Virginia, USA',
            'Visalia-Porterville MSA, CA, USA',
            'Vlaamse Gewest, Belgium',
            'Volgogradskaya oblast\', Russia',
            'Volusia County, Florida, USA',
            'WA-01, USA',
            'WA-02, USA',
            'WA-03, USA',
            'WA-04, USA',
            'WA-05, USA',
            'WA-06, USA',
            'WA-07, USA',
            'WA-08, USA',
            'WA-09, USA',
            'WA-10, USA',
            'WI-01, USA',
            'WI-02, USA',
            'WI-03, USA',
            'WI-04, USA',
            'WI-05, USA',
            'WI-06, USA',
            'WI-07, USA',
            'WI-08, USA',
            'WV-01, USA',
            'WV-02, USA',
            'WY-00, USA',
            'Waco MSA, TX, USA',
            'Waco-Temple-Bryan TX Media Market, USA',
            'Wake County, North Carolina, USA',
            'Wakiso, Uganda',
            'Wales, United Kingdom',
            'Washington County, Minnesota, USA',
            'Washington County, Oregon, USA',
            'Washington D.C. Urban Center, USA',
            'Washington DC (Hagerstown MD) Media Market, USA',
            'Washington city, District of Columbia, USA',
            'Washington, USA',
            'Washington-Arlington [Urban Area], DC-VA-MD, USA',
            'Washington-Arlington-Alexandria MSA, DC-VA-MD-WV, USA',
            'Washoe County, Nevada, USA',
            'Washtenaw County, Michigan, USA',
            'Waterloo Regional municipality, Ontario, Canada',
            'Watertown NY Media Market, USA',
            'Waukegan Urban Center, USA',
            'Waukesha County, Wisconsin, USA',
            'Wausau-Rhinelander WI Media Market, USA',
            'Wayne County, Michigan, USA',
            'Webb County, Texas, USA',
            'Weber County, Utah, USA',
            'Weld County, Colorado, USA',
            'Wenzhou Urban Center, China',
            'West Bengal, India',
            'West Palm Beach-Fort Pierce FL Media Market, USA',
            'West Virginia, USA',
            'Westchester County, New York, USA',
            'Western Australia, Australia',
            'Western Cape, South Africa',
            'Western Connecticut Planning Region, Connecticut, USA',
            'Westmoreland County, Pennsylvania, USA',
            'Wheeling WV-Steubenville OH Media Market, USA',
            'Wichita Falls TX & Lawton OK Media Market, USA',
            'Wichita MSA, KS, USA',
            'Wichita Urban Center, USA',
            'Wichita [Urban Area], KS, USA',
            'Wichita city, Kansas, USA',
            'Wichita-Hutchinson KS Media Market, USA',
            'Wielkopolskie, Poland',
            'Wilkes Barre-Scranton PA Media Market, USA',
            'Will County, Illinois, USA',
            'Williamson County, Texas, USA',
            'Wilmington MSA, NC, USA',
            'Wilmington NC Media Market, USA',
            'Wilmington [Urban Area], NC, USA',
            'Windsor CMA, ON, Canada',
            'Windsor Population Center, ON, Canada',
            'Winnebago County, Illinois, USA',
            'Winnipeg CMA, MB, Canada',
            'Winnipeg City, Division No. 11, Manitoba, Canada',
            'Winnipeg Population Center, MB, Canada',
            'Winnipeg Urban Center, Canada',
            'Winston-Salem MSA, NC, USA',
            'Winston-Salem [Urban Area], NC, USA',
            'Winter Haven [Urban Area], FL, USA',
            'Wisconsin, USA',
            'Worcester County, Massachusetts, USA',
            'Worcester MSA, MA-CT, USA',
            'Worcester [Urban Area], MA-CT, USA',
            'Wuhan Urban Center, China',
            'Wyoming, USA',
            'Xi\'an Urban Center, China',
            'Xiamen Urban Center, China',
            'Xinjiang Uygur Zizhiqu, China',
            'Xizang Zizhiqu, China',
            'Yakima County, Washington, USA',
            'Yakima MSA, WA, USA',
            'Yakima-Pasco-Richland-Kennewick WA Media Market, USA',
            'Yangon Urban Center, Myanmar',
            'Yangon, Myanmar',
            'Yaounde Urban Center, Cameroon',
            'YeDebub Bih\u0113roch Bih\u0113reseboch na Hizboch, Ethiopia',
            'Yemen',
            'Yobe, Nigeria',
            'Yogyakarta Urban Center, Indonesia',
            'Yogyakarta, Indonesia',
            'York County, Pennsylvania, USA',
            'York County, South Carolina, USA',
            'York Regional municipality, Ontario, Canada',
            'York-Hanover MSA, PA, USA',
            'Youngstown OH Media Market, USA',
            'Youngstown [Urban Area], OH, USA',
            'Youngstown-Warren-Boardman MSA, OH-PA, USA',
            'Yuma AZ-El Centro CA Media Market, USA',
            'Yunnan Sheng, China',
            'Zambia',
            'Zamb\u00e9zia, Mozambique',
            'Zamfara, Nigeria',
            'Zhejiang Sheng, China',
            'Zhengzhou Urban Center, China',
            'Zimbabwe',
            'Zinder, Niger',
            'Zuid-Holland, Netherlands',
            'Zulia, Venezuela',
            'nohiyahoi tobei jumhur\u00ed, Tajikistan',
            'wallonne, R\u00e9gion, Belgium',
            '\u00cele-de-France, France',
            '\u00d0\u1ed3ng Nai, Vietnam',
            '\u00d4saka, Japan',
            '\u00dcr\u00fcmqi Urban Center, China',
            '\u0100d\u012bs \u0100beba, Ethiopia',
            '\u0100mara, Ethiopia',
            '\u0100z\u0304arb\u0101yj\u0101n-e Gharb\u012b, Iran',
            '\u0100z\u0304arb\u0101yj\u0101n-e Sharq\u012b, Iran',
            '\u0130stanbul, Turkey',
            '\u0130zmir, Turkey',
            '\u015al\u0105skie, Poland',
            '\u015eal\u0101\u1e29 ad D\u012bn, Iraq',
            '\u1e28alab, Syria',
        ],
        allStats: [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            11,
            12,
            13,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            25,
            26,
            27,
            28,
            39,
            42,
            43,
            49,
            55,
            58,
            59,
            60,
            61,
            64,
            65,
            66,
            68,
            69,
            79,
            80,
            81,
            82,
            83,
            84,
            85,
            86,
            87,
            91,
            93,
            95,
            97,
            99,
            101,
            108,
            114,
            118,
            119,
            124,
            125,
            126,
            127,
            130,
            131,
            132,
            133,
            134,
            139,
            140,
            141,
            142,
            143,
            145,
            147,
            149,
            151,
            153,
            155,
            157,
            159,
            161,
            163,
            165,
            167,
            169,
            171,
            173,
            175,
            177,
            179,
            181,
            183,
            184,
            185,
            186,
            187,
            189,
            191,
            194,
            195,
            201,
            202,
            203,
            204,
            206,
            207,
            208,
            209,
            210,
            211,
            215,
            216,
            217,
            218,
            219,
            220,
            221,
            222,
            223,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            231,
            232,
            233,
            234,
            235,
            236,
            238,
            239,
            243,
            244,
            248,
            249,
            250,
            251,
            253,
            254,
            256,
            257,
            259,
            260,
            261,
            276,
            278,
            283,
            285,
            290,
            292,
            294,
            295,
            296,
            297,
            298,
            299,
            302,
            305,
            308,
            313,
            318,
        ],
        questionDistribution: [
            [
                {
                    path: 'quiz_sampling_info/1/q1/0.gz',
                    totalP: 0.13598239421844482,
                },
                {
                    path: 'quiz_sampling_info/1/q1/1.gz',
                    totalP: 0.09757756441831589,
                },
                {
                    path: 'quiz_sampling_info/1/q1/2.gz',
                    totalP: 0.08843813836574554,
                },
                {
                    path: 'quiz_sampling_info/1/q1/3.gz',
                    totalP: 0.10184188187122345,
                },
                {
                    path: 'quiz_sampling_info/1/q1/4.gz',
                    totalP: 0.11853096634149551,
                },
                {
                    path: 'quiz_sampling_info/1/q1/5.gz',
                    totalP: 0.08597700297832489,
                },
                {
                    path: 'quiz_sampling_info/1/q1/6.gz',
                    totalP: 0.12596189975738525,
                },
                {
                    path: 'quiz_sampling_info/1/q1/7.gz',
                    totalP: 0.10355039685964584,
                },
                {
                    path: 'quiz_sampling_info/1/q1/8.gz',
                    totalP: 0.12694931030273438,
                },
                {
                    path: 'quiz_sampling_info/1/q1/9.gz',
                    totalP: 0.015190508216619492,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/1/q2/0.gz',
                    totalP: 0.14673025906085968,
                },
                {
                    path: 'quiz_sampling_info/1/q2/1.gz',
                    totalP: 0.09321416169404984,
                },
                {
                    path: 'quiz_sampling_info/1/q2/2.gz',
                    totalP: 0.1077500656247139,
                },
                {
                    path: 'quiz_sampling_info/1/q2/3.gz',
                    totalP: 0.09002871066331863,
                },
                {
                    path: 'quiz_sampling_info/1/q2/4.gz',
                    totalP: 0.17028813064098358,
                },
                {
                    path: 'quiz_sampling_info/1/q2/5.gz',
                    totalP: 0.06415405124425888,
                },
                {
                    path: 'quiz_sampling_info/1/q2/6.gz',
                    totalP: 0.12868370115756989,
                },
                {
                    path: 'quiz_sampling_info/1/q2/7.gz',
                    totalP: 0.1306871622800827,
                },
                {
                    path: 'quiz_sampling_info/1/q2/8.gz',
                    totalP: 0.06846379488706589,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/1/q3/0.gz',
                    totalP: 0.11694368720054626,
                },
                {
                    path: 'quiz_sampling_info/1/q3/1.gz',
                    totalP: 0.11449010670185089,
                },
                {
                    path: 'quiz_sampling_info/1/q3/2.gz',
                    totalP: 0.08648458868265152,
                },
                {
                    path: 'quiz_sampling_info/1/q3/3.gz',
                    totalP: 0.0778813287615776,
                },
                {
                    path: 'quiz_sampling_info/1/q3/4.gz',
                    totalP: 0.12422139942646027,
                },
                {
                    path: 'quiz_sampling_info/1/q3/5.gz',
                    totalP: 0.11182024329900742,
                },
                {
                    path: 'quiz_sampling_info/1/q3/6.gz',
                    totalP: 0.03959804028272629,
                },
                {
                    path: 'quiz_sampling_info/1/q3/7.gz',
                    totalP: 0.04810687154531479,
                },
                {
                    path: 'quiz_sampling_info/1/q3/8.gz',
                    totalP: 0.11842415481805801,
                },
                {
                    path: 'quiz_sampling_info/1/q3/9.gz',
                    totalP: 0.1262359768152237,
                },
                {
                    path: 'quiz_sampling_info/1/q3/10.gz',
                    totalP: 0.03579362481832504,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/1/q4/0.gz',
                    totalP: 0.10969769209623337,
                },
                {
                    path: 'quiz_sampling_info/1/q4/1.gz',
                    totalP: 0.12897737324237823,
                },
                {
                    path: 'quiz_sampling_info/1/q4/2.gz',
                    totalP: 0.08638643473386765,
                },
                {
                    path: 'quiz_sampling_info/1/q4/3.gz',
                    totalP: 0.10407009720802307,
                },
                {
                    path: 'quiz_sampling_info/1/q4/4.gz',
                    totalP: 0.12337031215429306,
                },
                {
                    path: 'quiz_sampling_info/1/q4/5.gz',
                    totalP: 0.0716659277677536,
                },
                {
                    path: 'quiz_sampling_info/1/q4/6.gz',
                    totalP: 0.08976385742425919,
                },
                {
                    path: 'quiz_sampling_info/1/q4/7.gz',
                    totalP: 0.031971629709005356,
                },
                {
                    path: 'quiz_sampling_info/1/q4/8.gz',
                    totalP: 0.032241206616163254,
                },
                {
                    path: 'quiz_sampling_info/1/q4/9.gz',
                    totalP: 0.08614720404148102,
                },
                {
                    path: 'quiz_sampling_info/1/q4/10.gz',
                    totalP: 0.07542141526937485,
                },
                {
                    path: 'quiz_sampling_info/1/q4/11.gz',
                    totalP: 0.06028646603226662,
                },
            ],
            [
                {
                    path: 'quiz_sampling_info/1/q5/0.gz',
                    totalP: 0.024335427209734917,
                },
                {
                    path: 'quiz_sampling_info/1/q5/1.gz',
                    totalP: 0.027490895241498947,
                },
                {
                    path: 'quiz_sampling_info/1/q5/2.gz',
                    totalP: 0.027777355164289474,
                },
                {
                    path: 'quiz_sampling_info/1/q5/3.gz',
                    totalP: 0.02645501121878624,
                },
                {
                    path: 'quiz_sampling_info/1/q5/4.gz',
                    totalP: 0.027246614918112755,
                },
                {
                    path: 'quiz_sampling_info/1/q5/5.gz',
                    totalP: 0.031373098492622375,
                },
                {
                    path: 'quiz_sampling_info/1/q5/6.gz',
                    totalP: 0.012537983246147633,
                },
                {
                    path: 'quiz_sampling_info/1/q5/7.gz',
                    totalP: 0.018217990174889565,
                },
                {
                    path: 'quiz_sampling_info/1/q5/8.gz',
                    totalP: 0.01148033607751131,
                },
                {
                    path: 'quiz_sampling_info/1/q5/9.gz',
                    totalP: 0.0190809965133667,
                },
                {
                    path: 'quiz_sampling_info/1/q5/10.gz',
                    totalP: 0.02057676762342453,
                },
                {
                    path: 'quiz_sampling_info/1/q5/11.gz',
                    totalP: 0.023251129314303398,
                },
                {
                    path: 'quiz_sampling_info/1/q5/12.gz',
                    totalP: 0.021215511485934258,
                },
                {
                    path: 'quiz_sampling_info/1/q5/13.gz',
                    totalP: 0.021803798153996468,
                },
                {
                    path: 'quiz_sampling_info/1/q5/14.gz',
                    totalP: 0.020011184737086296,
                },
                {
                    path: 'quiz_sampling_info/1/q5/15.gz',
                    totalP: 0.029656967148184776,
                },
                {
                    path: 'quiz_sampling_info/1/q5/16.gz',
                    totalP: 0.028625892475247383,
                },
                {
                    path: 'quiz_sampling_info/1/q5/17.gz',
                    totalP: 0.024535421282052994,
                },
                {
                    path: 'quiz_sampling_info/1/q5/18.gz',
                    totalP: 0.039453402161598206,
                },
                {
                    path: 'quiz_sampling_info/1/q5/19.gz',
                    totalP: 0.06428781896829605,
                },
                {
                    path: 'quiz_sampling_info/1/q5/20.gz',
                    totalP: 0.01753440499305725,
                },
                {
                    path: 'quiz_sampling_info/1/q5/21.gz',
                    totalP: 0.017197761684656143,
                },
                {
                    path: 'quiz_sampling_info/1/q5/22.gz',
                    totalP: 0.025435293093323708,
                },
                {
                    path: 'quiz_sampling_info/1/q5/23.gz',
                    totalP: 0.019321205094456673,
                },
                {
                    path: 'quiz_sampling_info/1/q5/24.gz',
                    totalP: 0.01782405935227871,
                },
                {
                    path: 'quiz_sampling_info/1/q5/25.gz',
                    totalP: 0.009560183621942997,
                },
                {
                    path: 'quiz_sampling_info/1/q5/26.gz',
                    totalP: 0.045571789145469666,
                },
                {
                    path: 'quiz_sampling_info/1/q5/27.gz',
                    totalP: 0.045103639364242554,
                },
                {
                    path: 'quiz_sampling_info/1/q5/28.gz',
                    totalP: 0.00801666360348463,
                },
                {
                    path: 'quiz_sampling_info/1/q5/29.gz',
                    totalP: 0.01090974546968937,
                },
                {
                    path: 'quiz_sampling_info/1/q5/30.gz',
                    totalP: 0.04098346084356308,
                },
                {
                    path: 'quiz_sampling_info/1/q5/31.gz',
                    totalP: 0.008972760289907455,
                },
                {
                    path: 'quiz_sampling_info/1/q5/32.gz',
                    totalP: 0.007798986975103617,
                },
                {
                    path: 'quiz_sampling_info/1/q5/33.gz',
                    totalP: 0.008513270877301693,
                },
                {
                    path: 'quiz_sampling_info/1/q5/34.gz',
                    totalP: 0.009423915296792984,
                },
                {
                    path: 'quiz_sampling_info/1/q5/35.gz',
                    totalP: 0.009462327696383,
                },
                {
                    path: 'quiz_sampling_info/1/q5/36.gz',
                    totalP: 0.03476234897971153,
                },
                {
                    path: 'quiz_sampling_info/1/q5/37.gz',
                    totalP: 0.02697318233549595,
                },
                {
                    path: 'quiz_sampling_info/1/q5/38.gz',
                    totalP: 0.02597339078783989,
                },
                {
                    path: 'quiz_sampling_info/1/q5/39.gz',
                    totalP: 0.03484708070755005,
                },
                {
                    path: 'quiz_sampling_info/1/q5/40.gz',
                    totalP: 0.05640159174799919,
                },
            ],
        ],
        juxtaVersion: 1,
    },
] as const
