export default [
    '2016 Presidential Election-margin',
    '2016-2020 Swing-margin',
    '2020 Presidential Election-margin',
    'ARTHRITIS_cdc_2',
    'BINGE_cdc_2',
    'BPHIGH_cdc_2',
    'CANCER_cdc_2',
    'CASTHMA_cdc_2',
    'CHD_cdc_2',
    'CHECKUP_cdc_2',
    'CHOLSCREEN_cdc_2',
    'COGNITION_cdc_2',
    'COPD_cdc_2',
    'CSMOKING_cdc_2',
    'DENTAL_cdc_2',
    'DIABETES_cdc_2',
    'DISABILITY_cdc_2',
    'GHLTH_cdc_2',
    'HEARING_cdc_2',
    'INDEPLIVE_cdc_2',
    'KIDNEY_cdc_2',
    'LPA_cdc_2',
    'MOBILITY_cdc_2',
    'OBESITY_cdc_2',
    'PHLTH_cdc_2',
    'SELFCARE_cdc_2',
    'SLEEP_cdc_2',
    'STROKE_cdc_2',
    'VISION_cdc_2',
    'ad_0.25',
    'ad_0.25_2000',
    'ad_0.25_2010',
    'ad_0.25_change_2000',
    'ad_0.25_change_2010',
    'ad_0.5',
    'ad_0.5_2000',
    'ad_0.5_2010',
    'ad_0.5_change_2000',
    'ad_0.5_change_2010',
    'ad_1',
    'ad_1_2000',
    'ad_1_2010',
    'ad_1_change_2000',
    'ad_1_change_2010',
    'ad_2',
    'ad_2_2000',
    'ad_2_2010',
    'ad_2_change_2000',
    'ad_2_change_2010',
    'ad_4',
    'ad_4_2000',
    'ad_4_2010',
    'ad_4_change_2000',
    'ad_4_change_2010',
    'area',
    'asian',
    'asian_2000',
    'asian_2010',
    'birthplace_non_us',
    'birthplace_us_not_state',
    'birthplace_us_state',
    'black',
    'black_2000',
    'black_2010',
    'citizenship_citizen_by_birth',
    'citizenship_citizen_by_naturalization',
    'citizenship_not_citizen',
    'compactness',
    'days_above_90_4',
    'days_below_40_4',
    'days_between_40_and_90_4',
    'days_dewpoint_-inf_50_4',
    'days_dewpoint_50_70_4',
    'days_dewpoint_70_inf_4',
    'density_2021_pw_0.25_canada',
    'density_2021_pw_0.5_canada',
    'density_2021_pw_1_canada',
    'density_2021_pw_2_canada',
    'density_2021_pw_4_canada',
    'education_field_business',
    'education_field_humanities',
    'education_field_stem',
    'education_grad',
    'education_grad_canada',
    'education_high_school',
    'education_high_school_canada',
    'education_ugrad',
    'education_ugrad_canada',
    'female_grad_gap_4',
    'female_hs_gap_4',
    'female_ugrad_gap_4',
    'generation_boomer',
    'generation_boomer_canada',
    'generation_genalpha',
    'generation_genalpha_canada',
    'generation_genx',
    'generation_genx_canada',
    'generation_genz',
    'generation_genz_canada',
    'generation_millenial',
    'generation_millenial_canada',
    'generation_silent',
    'generation_silent_canada',
    'gpw_aw_density',
    'gpw_population',
    'gpw_pw_density_1',
    'gpw_pw_density_2',
    'gpw_pw_density_4',
    'gridded_elevation',
    'gridded_hilliness',
    'hawaiian_pi',
    'hawaiian_pi_2000',
    'hawaiian_pi_2010',
    'heating_bottled_tank_lp_gas',
    'heating_electricity',
    'heating_feul_oil_kerosene',
    'heating_no',
    'heating_other',
    'heating_utility_gas',
    'hispanic',
    'hispanic_2000',
    'hispanic_2010',
    'homogeneity_250_2000',
    'homogeneity_250_2010',
    'homogeneity_250_2020',
    'homogeneity_250_diff_2000',
    'homogeneity_250_diff_2010',
    'hours_sunny_4',
    'household_income_50_to_100cad',
    'household_income_50k_to_100k',
    'household_income_above_100_cad',
    'household_income_over_100k',
    'household_income_under_50cad',
    'household_income_under_50k',
    'housing_per_pop',
    'housing_per_pop_2000',
    'housing_per_pop_2010',
    'individual_income_50_to_100cad',
    'individual_income_50k_to_100k',
    'individual_income_above_100_cad',
    'individual_income_over_100k',
    'individual_income_under_50cad',
    'individual_income_under_50k',
    'industry_accommodation_and_food_services',
    'industry_accommodation_and_food_services_canada',
    'industry_administrative_and_support_and_waste_management_services',
    'industry_administrative_and_support_and_waste_management_services_canada',
    'industry_agriculture,_forestry,_fishing_and_hunting',
    'industry_agriculture,_forestry,_fishing_and_hunting_canada',
    'industry_arts,_entertainment,_and_recreation',
    'industry_arts,_entertainment,_and_recreation_canada',
    'industry_construction',
    'industry_construction_canada',
    'industry_educational_services',
    'industry_educational_services_canada',
    'industry_finance_and_insurance',
    'industry_finance_and_insurance_canada',
    'industry_health_care_and_social_assistance',
    'industry_health_care_and_social_assistance_canada',
    'industry_information',
    'industry_information_canada',
    'industry_management_of_companies_and_enterprises',
    'industry_management_of_companies_and_enterprises_canada',
    'industry_manufacturing',
    'industry_manufacturing_canada',
    'industry_mining,_quarrying,_and_oil_and_gas_extraction',
    'industry_mining,_quarrying,_and_oil_and_gas_extraction_canada',
    'industry_other_services,_except_public_administration',
    'industry_other_services,_except_public_administration_canada',
    'industry_professional,_scientific,_and_technical_services',
    'industry_professional,_scientific,_and_technical_services_canada',
    'industry_public_administration',
    'industry_public_administration_canada',
    'industry_real_estate_and_rental_and_leasing',
    'industry_real_estate_and_rental_and_leasing_canada',
    'industry_retail_trade',
    'industry_retail_trade_canada',
    'industry_transportation_and_warehousing',
    'industry_transportation_and_warehousing_canada',
    'industry_utilities',
    'industry_utilities_canada',
    'industry_wholesale_trade',
    'industry_wholesale_trade_canada',
    'insurance_coverage_govt',
    'insurance_coverage_none',
    'insurance_coverage_private',
    'internet_no_access',
    'language_english_only',
    'language_other',
    'language_spanish',
    'lapop10share_usda_fra_1',
    'lapop1share_usda_fra_1',
    'lapop20share_usda_fra_1',
    'lapophalfshare_usda_fra_1',
    'lico_at_canada',
    'marriage_divorced',
    'marriage_divorced_canada',
    'marriage_married_not_divorced',
    'marriage_married_not_divorced_canada',
    'marriage_never_married',
    'marriage_never_married_canada',
    'mean_dist_Active Superfund Site_updated',
    'mean_dist_Airport_updated',
    'mean_dist_Hospital_updated',
    'mean_dist_Public School_updated',
    'mean_high_dewpoint_4',
    'mean_high_heat_index_4',
    'mean_high_temp_4',
    'mean_high_temp_fall_4',
    'mean_high_temp_spring_4',
    'mean_high_temp_summer_4',
    'mean_high_temp_winter_4',
    'native',
    'native_2000',
    'native_2010',
    'occupation_architecture_and_engineering_occupations',
    'occupation_arts,_design,_entertainment,_sports,_and_media_occupations',
    'occupation_building_and_grounds_cleaning_and_maintenance_occupations',
    'occupation_business_and_financial_operations_occupations',
    'occupation_community_and_social_service_occupations',
    'occupation_computer_and_mathematical_occupations',
    'occupation_construction_and_extraction_occupations',
    'occupation_educational_instruction,_and_library_occupations',
    'occupation_farming,_fishing,_and_forestry_occupations',
    'occupation_firefighting_and_prevention,_and_other_protective_service_workers_including_supervisors',
    'occupation_food_preparation_and_serving_related_occupations',
    'occupation_health_diagnosing_and_treating_practitioners_and_other_technical_occupations',
    'occupation_health_technologists_and_technicians',
    'occupation_healthcare_support_occupations',
    'occupation_installation,_maintenance,_and_repair_occupations',
    'occupation_law_enforcement_workers_including_supervisors',
    'occupation_legal_occupations',
    'occupation_life,_physical,_and_social_science_occupations',
    'occupation_management_occupations',
    'occupation_material_moving_occupations',
    'occupation_office_and_administrative_support_occupations',
    'occupation_personal_care_and_service_occupations',
    'occupation_production_occupations',
    'occupation_sales_and_related_occupations',
    'occupation_transportation_occupations',
    'other  slash  mixed',
    'other  slash  mixed_2000',
    'other  slash  mixed_2010',
    'park_percent_1km_v2',
    'population',
    'population_2000',
    'population_2010',
    'population_2021_canada',
    'population_change_2000',
    'population_change_2010',
    'poverty_below_line',
    'rainfall_4',
    'rent_1br_750_to_1500',
    'rent_1br_over_1500',
    'rent_1br_under_750',
    'rent_2br_750_to_1500',
    'rent_2br_over_1500',
    'rent_2br_under_750',
    'rent_burden_20_to_40',
    'rent_burden_over_40',
    'rent_burden_under_20',
    'rent_or_own_rent',
    'sd',
    'sd_2000',
    'sd_2010',
    'sd_2021_canada',
    'segregation_250_10_2000',
    'segregation_250_10_2010',
    'segregation_250_10_2020',
    'segregation_250_10_diff_2000',
    'segregation_250_10_diff_2010',
    'segregation_250_2000',
    'segregation_250_2010',
    'segregation_250_2020',
    'segregation_250_diff_2000',
    'segregation_250_diff_2010',
    'snowfall_4',
    'sors_child',
    'sors_cohabiting_partnered_gay',
    'sors_cohabiting_partnered_straight',
    'sors_other',
    'sors_unpartnered_householder',
    'traffic_fatalities_last_decade',
    'traffic_fatalities_last_decade_per_capita',
    'traffic_fatalities_ped_last_decade',
    'traffic_fatalities_ped_last_decade_per_capita',
    'transportation_commute_time_15_to_29',
    'transportation_commute_time_15_to_29_canada',
    'transportation_commute_time_30_to_59',
    'transportation_commute_time_30_to_59_canada',
    'transportation_commute_time_over_60',
    'transportation_commute_time_over_60_canada',
    'transportation_commute_time_under_15',
    'transportation_commute_time_under_15_canada',
    'transportation_means_bike',
    'transportation_means_car',
    'transportation_means_transit',
    'transportation_means_walk',
    'transportation_means_worked_at_home',
    'vacancy',
    'vacancy_2000',
    'vacancy_2010',
    'vehicle_ownership_at_least_1',
    'vehicle_ownership_at_least_2',
    'vehicle_ownership_none',
    'white',
    'white_2000',
    'white_2010',
    'wind_speed_over_10mph_4',
    'within_Active Superfund Site_10',
    'within_Airport_30',
    'within_Hospital_10',
    'within_Public School_2',
    'year_built_1969_or_earlier',
    'year_built_1970_to_1979',
    'year_built_1980_to_1989',
    'year_built_1990_to_1999',
    'year_built_2000_to_2009',
    'year_built_2010_or_later',
] as const
