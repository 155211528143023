export default [
    [
        29,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        30,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        31,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        34,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        35,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        36,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        39,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        40,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        41,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        44,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        45,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        46,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        49,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        50,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        51,
        {
            type: 'histogram',
            universe_total_idx: 244,
        },
    ],
    [
        74,
        {
            type: 'histogram',
            universe_total_idx: 247,
        },
    ],
    [
        75,
        {
            type: 'histogram',
            universe_total_idx: 247,
        },
    ],
    [
        76,
        {
            type: 'histogram',
            universe_total_idx: 247,
        },
    ],
    [
        77,
        {
            type: 'histogram',
            universe_total_idx: 247,
        },
    ],
    [
        78,
        {
            type: 'histogram',
            universe_total_idx: 247,
        },
    ],
    [
        105,
        {
            type: 'histogram',
            universe_total_idx: 104,
        },
    ],
    [
        106,
        {
            type: 'histogram',
            universe_total_idx: 104,
        },
    ],
    [
        107,
        {
            type: 'histogram',
            universe_total_idx: 104,
        },
    ],
] as const
