export default [
    '!FULL Which voted more for Clinton in the 2016 presidential election?',
    '!FULL Which swung towards Democrats more from 2016 to 2020?',
    '!FULL Which voted more for Biden in the 2020 presidential election?',
    'higher % of adults with arthritis',
    'higher % of adults who binge drink',
    'higher % of adults with high blood pressure',
    'higher % of adults with cancer (excluding skin cancer)',
    'higher % of adults with asthma',
    'higher % of adults with coronary heart disease',
    null,
    null,
    'higher % of adults with a cognitive disability',
    'higher % of adults with COPD !TOOLTIP (Chronic obstructive pulmonary disease, a lung disease linked to smoking)',
    'higher % of adults who smoke',
    null,
    'higher % of adults with diagnosed diabetes',
    'higher % of adults with a disability',
    'higher % of adults with a fair or poor self-rated health status',
    'higher % of adults with a hearing disability',
    'higher % of adults with an independent living disability',
    'higher % of adults with chronic kidney disease',
    'higher % of adults who don\'t exercise (do leisure-time physical activity)',
    'higher % of adults with a mobility disability',
    'higher % of adults with obesity',
    null,
    'higher % of adults with a self-care disability',
    'higher % of adults who sleep less than 7 hours',
    'higher % of adults who have had a stroke',
    'higher % of adults with a vision disability',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'higher population-weighted density (r=1km)!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
    null,
    null,
    'higher % increase in population-weighted density (r=1km) from 2000 to 2020!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
    'higher % increase in population-weighted density (r=1km) from 2010 to 2020!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
    null,
    null,
    null,
    null,
    null,
    'higher population-weighted density (r=4km)!TOOLTIP Population-weighted density is computed by computing the density within the given radius for each person in the region and then averaging the results. This is a better measure of the density that people actually experience.',
    null,
    null,
    null,
    null,
    null,
    'higher % of people who are Asian',
    null,
    null,
    'higher % of people who were born outside the US',
    'higher % of people who were born in the US and outside their state of residence',
    'higher % of people who were born in their state of residence',
    'higher % of people who are Black',
    null,
    null,
    'higher % of residents who are citizens by birth',
    'higher % of residents who are citizens by naturalization',
    'higher % of residents who are non-citizens',
    null,
    'higher % of hot days (days with high temp over 90\u00b0F, population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    'higher % of cold days (days with high temp under 40\u00b0F, population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    '!FULL Which has more people with a business degree, as a percentage of the overall population?',
    '!FULL Which has more people with a humanities degree, as a percentage of the overall population?',
    '!FULL Which has more people with a STEM degree, as a percentage of the overall population?',
    'higher % of people who have a graduate degree',
    'higher % of people between 25 and 64 who have a graduate degree',
    'higher % of people who have at least a high school diploma',
    'higher % of people between 25 and 64 who have at least a high school diploma',
    'higher % of people who have at least an undergrad degree',
    'higher % of people between 25 and 64 who have at least an undergrad degree',
    null,
    null,
    null,
    'higher % of people who are boomers!TOOLTIP born between 1946 and 1966',
    null,
    'higher % of people who are gen alpha!TOOLTIP born between 2012 and 2021',
    null,
    'higher % of people who are gen x!TOOLTIP born between 1967 and 1981',
    null,
    'higher % of people who are gen z!TOOLTIP born between 1997 and 2011',
    null,
    'higher % of people who are millennials!TOOLTIP born between 1982 and 1996',
    null,
    'higher % of people who are silent generation!TOOLTIP born before 1946',
    null,
    null,
    null,
    null,
    null,
    null,
    'higher population-weighted mean elevation !TOOLTIP Population weighted elevation/hilliness statistics are calculated by computing the statistic for each person in the region and then averaging the results.',
    null,
    null,
    null,
    null,
    null,
    '!FULL Which has a larger % of homes heated using electricity?',
    null,
    null,
    null,
    '!FULL Which has a larger % of homes heated using utility gas?',
    'higher % of people who are Hispanic',
    null,
    null,
    null,
    null,
    'higher racial homogeneity!TOOLTIP We define racial homogeneity as the average probability a person selecing a random person in a 250m radius will select someone of the same race',
    'higher increase (or smaller decrease) in racial homogeneity from 2000 to 2020!TOOLTIP We define racial homogeneity as the average probability a person selecing a random person in a 250m radius will select someone of the same race',
    'higher increase (or smaller decrease) in racial homogeneity from 2010 to 2020!TOOLTIP We define racial homogeneity as the average probability a person selecing a random person in a 250m radius will select someone of the same race',
    '!FULL Which has more hours of sun per day on average? (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    null,
    null,
    'higher % of households that have household income over C$100k',
    'higher % of households who have household income over $100k',
    'higher % of households that have household income under C$50k',
    'higher % of households who have household income under $50k',
    'higher number of housing units per adult',
    null,
    null,
    null,
    null,
    'higher % of people who have individual income over C$100k',
    'higher % of people who have individual income over $100k',
    'higher % of people who have individual income under C$50k',
    'higher % of people who have individual income under $50k',
    'higher % of workers employed in the accommodation and food services industry!TOOLTIP hotels, restaurants, bars, caterers, food services contractors, etc.',
    null,
    'higher % of workers employed in the administrative/support/waste management services industries!TOOLTIP office administrative services, employment services, business support services, waste management and remediation services, etc.',
    null,
    'higher % of workers employed in the agriculture, forestry, fishing, and hunting industries!TOOLTIP agriculture, forestry, logging, fishing, and hunting',
    null,
    'higher % of workers employed in the arts, entertainment, and recreation industry!TOOLTIP performing arts companies, sports, museums, amusement parks, gambling industries, etc.',
    null,
    'higher % of workers employed in the construction industry!TOOLTIP construction',
    null,
    'higher % of workers employed in the educational services industry!TOOLTIP elementary and secondary schools, colleges, universities,etc.',
    null,
    'higher % of workers employed in the finance and insurance industry!TOOLTIP banks, credit unions, insurance carriers, securities and commodity contracts, etc.',
    null,
    'higher % of workers employed in the health care and social assistance industry!TOOLTIP hospitals, nursing care facilities, offices of physicians, dentists, day care centers, etc.',
    null,
    'higher % of workers employed in the information industry!TOOLTIP publishing industries, software publishers, data processing services, broadcasting, libraries, etc.',
    null,
    'higher % of workers employed in the management industry!TOOLTIP management of companies and enterprises',
    null,
    'higher % of workers employed in the manufacturing industry!TOOLTIP metal manufacturing, machinery manufacturing, cement and concrete product manufacturing, sawmills, wood product manufacturing, food and beverage manufacturing, textile mills, apparel manufacturing, paper manufacturing, printing, chemical manufacturing, plastics and rubber products manufacturing, etc.',
    null,
    'higher % of workers employed in the mining, quarrying, and oil/gas extraction industries!TOOLTIP mining, quarrying, and oil and gas extraction',
    null,
    'higher % of workers employed in other service industries, except public administration!TOOLTIP repair and maintenance, personal care services, beauty salons, funeral homes, religious organizations, civic and social organizations, etc.',
    null,
    'higher % of workers employed in the professional, scientific, and technical services industry!TOOLTIP legal services, accounting services, architectural services, engineering services, computer systems design services, management consulting services, etc.',
    null,
    'higher % of workers employed in public administration!TOOLTIP executive offices, legislative bodies, public finance activities, public order and safety activities, etc.',
    null,
    'higher % of workers employed in the real estate and rental and leasing industry!TOOLTIP real estate, rental and leasing, lessors of real estate, etc.',
    null,
    'higher % of workers employed in the retail trade industry!TOOLTIP retailers of all kinds of goods, including stores, gas stations, florists, etc.',
    null,
    'higher % of workers employed in the transportation and warehousing industry!TOOLTIP transportation by all means, taxis, buses, sightseeing transportation, warehousing, etc.',
    null,
    'higher % of workers employed in the utilities industry!TOOLTIP electrical power generation, transmission, and distribution; natural gas distribution; water, sewage, and other systems',
    null,
    'higher % of workers employed in the wholesale trade industry!TOOLTIP wholesalers of all kinds of goods',
    null,
    'higher % of people who are on public insurance',
    'higher % of people who are uninsured',
    'higher % of people who are on private insurance',
    'higher % of people who have no internet access',
    'higher % of people who only speak english at home',
    null,
    'higher % of people who speak spanish at home',
    null,
    '!FULL Which has more access to grocery stores (higher % of people within 1mi of a grocery store)?!TOOLTIP The USDA defines a grocery store as a \'supermarket, supercenter, or large grocery store.\'',
    null,
    null,
    'higher % of people who are low income based on the Low-income cut-offs, after tax (LICO-AT)',
    'higher % of people who are divorced',
    null,
    null,
    null,
    null,
    null,
    '!FULL Which has less exposure to active EPA superfund sites (higher population-weighted mean distance)?!TOOLTIP EPA superfund sites are hazardous waste sites identified by the Environmental Protection Agency.',
    '!FULL Which has less access to airports (higher population-weighted mean distance)?',
    '!FULL Which has less access to hospitals (higher population-weighted mean distance)?',
    '!FULL Which has less access to public schools (higher population-weighted mean distance)?',
    null,
    'higher mean daily high heat index (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    'higher mean daily high temperature (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    'higher mean daily high temperature in fall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    'higher mean daily high temperature in spring (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    'higher mean daily high temperature in summer (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    'higher mean daily high temperature in winter (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    null,
    null,
    null,
    'higher % of workers employed as architects and engineers!TOOLTIP all kinds of engineers except software engineers, architects, surveyors, drafters, etc.',
    'higher % of workers employed in arts, design, entertainment, sports, and media occupations!TOOLTIP artists, designers, musicians, actors, dancers, athletes, journalists, editors, writers, photographers, etc.',
    'higher % of workers employed in building and grounds cleaning and maintenance occupations!TOOLTIP janitors, maids, groundskeepers (gardeners), pest control workers, etc.',
    'higher % of workers employed in business and financial operations occupations!TOOLTIP sales agents, insurance agents, compliance officers, consultants, fundraisers, accountants, auditors, HR workers, etc.',
    'higher % of workers employed in community and social service occupations!TOOLTIP social workers, therapists, counselors, probation officers, clergy, etc.',
    'higher % of workers employed in computer and mathematical occupations!TOOLTIP software developers, software QA engineers, system administrators, actuaries, operations researchers',
    'higher % of workers employed in construction and extraction occupations!TOOLTIP carpenters, electricians, plumbers, roofers, miners, etc.',
    'higher % of workers employed in educational instruction, and library occupations!TOOLTIP teachers, tutors, professors, librarians, and archivists',
    'higher % of workers employed in farming, fishing, and forestry occupations!TOOLTIP farmers, ranchers, fishers, loggers, forest workers, etc.',
    'higher % of workers employed as firefighting and prevention, and other protective service workers including supervisors!TOOLTIP firefighters, fire inspectors, correctional officers, bailiffs, etc. (effectively, all protective service workers except police officers)',
    'higher % of workers employed as food preparers or servers!TOOLTIP cooks, waiters, bartenders, fast food workers, etc.',
    'higher % of workers employed in health diagnosing and treating practitioners and other technical occupations!TOOLTIP doctors, registered nurses, physical therapists, pharmacists, dietitians, veterinarians, paramedics, etc.',
    'higher % of workers employed as health technologists and technicians!TOOLTIP radiology technicians, lab technicians, hospital machinery technicians, etc.',
    'higher % of workers employed in healthcare support occupations!TOOLTIP nursing assistants, orderlies, home health aides, massage therapists, dental assistants, etc.',
    'higher % of workers employed in installation, maintenance, and repair occupations!TOOLTIP mechanics, HVAC technicians, electricians, plumbers, etc.',
    'higher % of workers employed as law enforcement workers including supervisors!TOOLTIP police officers, detectives, etc.',
    'higher % of workers employed in legal occupations!TOOLTIP lawyers, judges, paralegals, legal assistants, etc.',
    'higher % of workers employed in life, physical, and social science occupations!TOOLTIP biological scientists, chemists, physicists, geologists, food scientists, economists, phychologists, urban planners, sociologists, scientific technicians, etc.',
    'higher % of workers employed as managers!TOOLTIP a variety of roles titled \'managers\'',
    'higher % of workers employed as material movers!TOOLTIP forklift operators, stock clerks, conveyor operators, etc.',
    'higher % of workers employed as office and administrative support workers!TOOLTIP secretaries, receptionists, data entry clerks, office clerks, mail carriers, shipping clerks, etc.',
    'higher % of workers employed in personal care and service occupations!TOOLTIP hairdressers, childcare workers, fitness trainers, funeral service workers, travel guides, animal trainers, etc.',
    null,
    'higher % of workers employed in sales and related occupations!TOOLTIP retail salespersons, cashiers, telemarketers, real estate agents, travel agents, travelling salespeople, etc.',
    'higher % of workers employed in transportation occupations!TOOLTIP truck drivers, bus drivers, taxi drivers, pilots, flight attendants, sailors, etc.',
    null,
    null,
    null,
    '!FULL Which has more access to parks (higher % of area within 1km of a park, population weighted)?',
    'higher population',
    null,
    null,
    null,
    'higher % increase in population from 2000 to 2020',
    'higher % increase in population from 2010 to 2020',
    'higher % of people who are in poverty',
    'higher rainfall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    null,
    'higher % of units with 1br rent over $1500',
    'higher % of units with 1br rent under $750',
    null,
    'higher % of units with 2br rent over $1500',
    'higher % of units with 2br rent under $750',
    null,
    'higher % of people whose rent is greater than 40% of their income',
    'higher % of people whose rent is less than 20% of their income',
    'higher % of people who are renters',
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    'higher snowfall (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    null,
    'higher % of people who are gay and cohabiting with a partner/spouse',
    null,
    null,
    null,
    null,
    'higher traffic fatalities per capita between 2013 and 2022!TOOLTIP traffic fatalities in the region, divided by the population of the region',
    null,
    'higher pedestrian/cyclist fatalities per capita between 2013 and 2022!TOOLTIP pedestrian and cyclist fatalities in the region, divided by the population of the region',
    null,
    null,
    null,
    null,
    'higher % of people who have commute time over 60 min',
    null,
    'higher % of people who have commute time under 15 min',
    null,
    'higher % of people who commute by bike',
    'higher % of people who commute by car',
    'higher % of people who commute by transit',
    'higher % of people who commute by walking',
    'higher % of people who work from home',
    'higher % of units that are vacant',
    null,
    null,
    'higher % of households with at least 1 vehicle',
    null,
    null,
    'higher % of people who are White',
    null,
    null,
    'higher % of days with wind speed over 10mph (population weighted)!TOOLTIP Population weighted weather statistics are calculated by computing the weather statistic for each person in the region and then averaging the results.',
    null,
    null,
    null,
    null,
    'higher % units built pre-1970',
    null,
    null,
    null,
    null,
    'higher % units built in 2010s+',
] as const
